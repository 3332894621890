import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DomainContext, UserContext } from "../context";
import {
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { AccountCircle, ArrowBackOutlined, SaveAsTwoTone } from "@mui/icons-material";
import { CardDivideComponent } from "../components";
import useForm from "../hooks/useForm";
import { useState } from "react";

const User = () => {
  const { _uuid } = useParams();
  const navigate = useNavigate()

  const {
    state: { user },
    getUser,
    putUser,
    getClearUser,
    postUser
  } = useContext(UserContext);

  const {
    setTitle,
  } = useContext(DomainContext);

  useEffect(() => {
    if (_uuid !== "new") {
      setTitle("Dati utente");
      getUser(_uuid);
    }
    return () => {
      getClearUser()
    }
  }, []);

  const {
    values: FormUsers,
    changeHandler,
    touched,
    errors,
    setValues,
    isValid,
    ValidateValue,
  } = useForm(
    user,
    []
  );

  useEffect(() => {
  setValues(user)
  }, [user]);

  
  


  return (
    <Grid container spacing={2}>
      <CardDivideComponent title={"Utenza"}>
      <Grid item xs={12} md={12} lg={12} p={2}>
        <TextField
          fullWidth
          id="username"
          label="Username"
          size="small"
          value={FormUsers?.username || ""}
          onChange={(e)=> changeHandler("username", e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} p={2}>
        <TextField
          fullWidth
          id="password"
          label="password"
          size="small"
          /* disabled ={_uuid !== "new"} */
          value={FormUsers?.passwd || ""}
          onChange={(e)=> changeHandler("passwd", e.target.value)}
          type="password"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Grid>
      </CardDivideComponent>
      <CardDivideComponent title={"Personali"}>
      <Grid item xs={12} md={12} lg={12} p={2}>
        <TextField
          fullWidth
          id="cognome"
          label="Cognome"
          size="small"
          onChange={(e)=> changeHandler("last_name", e.target.value)}
          value={FormUsers?.last_name || ""}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} p={2}>
        <TextField
          id="firstname"
          label="Nome"
          size="small"
          fullWidth
          value={FormUsers?.first_name || ""}
          onChange={(e)=> changeHandler("first_name", e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} p={2}>
        <TextField
          id="email"
          label="Email"
          size="small"
          fullWidth
          value={FormUsers?.email || ""}
          onChange={(e)=> changeHandler("email", e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
      </Grid>
      </CardDivideComponent>

      <Grid item xs={12} md={12} lg={12} p={2}>
        <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
        <Button onClick={()=> navigate(-1)} startIcon={<ArrowBackOutlined/>} variant="contained" color="warning">Indietro</Button>
        <Button startIcon={<SaveAsTwoTone/>} onClick={()=> _uuid !== "new" ?  putUser(FormUsers, navigate) : postUser(FormUsers, navigate)} variant="contained" >Salva</Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default User;
