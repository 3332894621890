import {
  AddTwoTone,
  ArrowBack,
  AutoAwesome,
  AutoFixHigh,
  CancelTwoTone,
  KeyTwoTone,
  LocalOffer,
  NumbersTwoTone,
  SaveAsTwoTone,
  SignpostTwoTone,
  SmartToy,
  TagTwoTone,
  Title,
  UpdateTwoTone,
} from "@mui/icons-material";
import {
  Button,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Card,
  InputAdornment,
  CardContent,
  CardHeader,
  Avatar,
  IconButton,
  Dialog,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItemButton,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tiptap from "../components/TipTap";
/* import { PuntiVenditaContext } from "../context"; */
import useForm from "../hooks/useForm";
import { DomainContext } from "../context";
import { rimuoviCaratteriSpeciali } from "../utilities";
import { useTheme } from "@emotion/react";

const Post = ({ setOpen }) => {
  const {
    state: { post, type_domain },
    getDomainPost,
    getTypeDomain,
    putDomainPost,
    postDomainPost,
    pulisciPost,
    cleanPostTag,
    cleanBrandTarget,
    beautifyTextOpenAi,
    expandTextOpenAi,
    setTitle,
    putDomainPostAll,
    LokiVariantGenerate,
    generateMetaDescription,
    generateTenTitle,
    generateMetaTitle,
  } = useContext(DomainContext);

  const { id_domain, id_post, id_sito, uuid_domain } = useParams();
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState("");
  const [isHtml, setIsHtml] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [enancheTitle, setEnancheTitle] = useState([]);
  const [keyWords, setkeyWords] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const {
    values: postForm,
    changeHandler,
    touched,
    errors,
    setValues,
    /* isValid, */
  } = useForm(
    {
      post_content: "",
      title_post: "",
      slug_post: "",
      tag_title: "",
      meta_description: "",
      key_words: [],
    }
    /*validations */
  );

  useEffect(() => {
    if (id_post && id_domain) {
      getDomainPost(id_domain, id_post);
      getTypeDomain(id_domain);
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
    return () => {
      pulisciPost();
    };
  }, [id_post, id_domain]);

  useEffect(() => {
    if (id_post && id_domain) {
      post.forEach((element) => {
        /*  console.log("element: ", element); */
        if (element.key_words) {
          setValues({ ...element, key_words: element.key_words });
        } else {
          setValues({ ...element, key_words: [] });
        }
      });
      setTitle("post: " + postForm.title_post);
    } else {
      setValues({
        post_content: "",
        title_post: "",
        slug_post: "",
        id_post: "",
        link_post: "",
        key_words: [],
      });
    }
    setTitle("post " + postForm.title_post);
  }, [post]);

  const handleSave = () => {
    if (id_post && id_domain) {
      putDomainPost(id_domain, id_post, postForm, navigate);
    } else {
      let data = {
        ...postForm,
        list_domain_id: id_domain,
      };
      postDomainPost(id_domain, data, navigate, setTitle);
    }
  };

  const handlePutAll = (lists_domain_id, data) => {
    if (id_post && id_domain) {
      putDomainPostAll(
        id_domain,
        id_post,
        postForm,
        navigate,
        lists_domain_id,
        data
      );
    }
  };

  useEffect(() => {
    setValues({
      ...postForm,
      post_content: description,
    });
  }, [description]);

  /* console.log("postForm: ", postForm); */
  useEffect(() => {
    if (!id_post) {
      setTitle("nuovo post:" + postForm.title_post);
    }
  }, [postForm.title_post]);

  useEffect(() => {
    if (post) {
      post?.forEach((element) => {
        setDescription(element.post_content);
      });
    }
  }, [post]);

  const reloadPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={openModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Scegli tra questi suggerimenti"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <List component="nav" aria-label="main mailbox folders">
              {enancheTitle.map((res, idx) => (
                <ListItemButton
                  key={idx}
                  onClick={() => {
                    setValues({ ...postForm, title_post: res });
                    handleClose();
                  }}
                >
                  <ListItemIcon>
                    <Title />
                  </ListItemIcon>
                  <ListItemText primary={res} />
                </ListItemButton>
              ))}
            </List>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
      {!loading ? (
        <Grid container spacing={2} p={2}>
          <Grid item xs={12} md={12} lg={12}>
            {id_post && id_domain && type_domain === "clone" && (
              <Stack direction={"row"} spacing={2} ml={2}>
                <Chip
                  disabled={post[0]?.state === "disabled"}
                  label="Elimina tag"
                  clickable
                  icon={<TagTwoTone color="error" />}
                  onClick={() => {
                    cleanPostTag(
                      {
                        sito: id_sito,
                        uuid_domain: uuid_domain,
                        uuid_post: post[0]?._uuid,
                        post_content: postForm.post_content,
                      },
                      true,
                      id_domain,
                      id_post
                    );
                    reloadPage();
                  }}
                />
                <Chip
                  label="Genera/Modifica contenuto"
                  clickable
                  disabled={post[0]?.state === "disabled"}
                  icon={<SmartToy color="info" />}
                  onClick={() =>
                    beautifyTextOpenAi(
                      {
                        sito: id_sito,
                        uuid_domain: uuid_domain,
                        uuid_post: post[0]?._uuid,
                        post_content: postForm.post_content,
                      },
                      true,
                      id_domain,
                      id_post
                    )
                  }
                />
                <Chip
                  label="Amplia contenuto"
                  clickable
                  disabled={post[0]?.state === "disabled"}
                  icon={<AutoAwesome color="info" />}
                  onClick={() =>
                    expandTextOpenAi(
                      {
                        sito: id_sito,
                        uuid_domain: uuid_domain,
                        uuid_post: post[0]?._uuid,
                        post_content: postForm.post_content,
                      },
                      true,
                      id_domain,
                      id_post
                    )
                  }
                />
                <Chip
                  label="Elimina brand"
                  clickable
                  disabled={post[0]?.state === "disabled"}
                  icon={<LocalOffer color="error" />}
                  onClick={() => {
                    cleanBrandTarget(
                      {
                        sito: id_sito,
                        uuid_domain: uuid_domain,
                        uuid_post: post[0]?._uuid,
                        post_content: postForm.post_content,
                      },
                      true,
                      id_domain,
                      id_post
                    );
                    reloadPage();
                  }}
                />
                <Chip
                  label="Genera Varianti"
                  clickable
                  disabled={post[0]?.state === "disabled"}
                  icon={<SignpostTwoTone color="success" />}
                  onClick={() => {
                    LokiVariantGenerate(
                      {
                        sito: id_sito,
                        uuid_domain: uuid_domain,
                        uuid_post: post[0]?._uuid,
                        post_content: postForm.post_content,
                      },
                      true,
                      id_domain,
                      id_post
                    );
                    /* reloadPage(); */
                  }}
                />
              </Stack>
            )}
            {id_post && id_domain && type_domain === "update" && (
              <Stack direction={"row"} spacing={2} ml={2}>
                <Chip
                  /* disabled={post[0]?.state === "disabled"} */
                  disabled
                  label="AI prompt"
                  clickable
                  icon={<SmartToy color="info" />}
                  onClick={() => ""}
                />
                <Tooltip title="Questa azione aggiorna contemporaneamente i dati su questo sito e sul sito WordPress di destinazione">
                  <Chip
                    label="Aggiorna Post"
                    clickable
                    icon={<UpdateTwoTone color="info" />}
                    onClick={() =>
                      handlePutAll(postForm.lists_domain_id, {
                        post_content: postForm.post_content,
                        slug_post: postForm.slug_post,
                        id_post: postForm.id_post,
                        title_post: postForm.title_post,
                        link_post: postForm.link_post,
                      })
                    }
                  />
                </Tooltip>
              </Stack>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              margin="normal"
              fullWidth
              label="Title Post"
              autoComplete="off"
              autoFocus
              value={postForm.title_post || ""}
              onBlur={() =>
                setValues({
                  ...postForm,
                  slug_post: rimuoviCaratteriSpeciali(postForm.title_post)
                    .toLowerCase()
                    .replaceAll(" ", "-"),
                })
              }
              helperText={touched.title_post && errors.title_post}
              error={Boolean(touched.title_post && errors.title_post)}
              onChange={(e) => changeHandler("title_post", e.target.value)}
              InputProps={{
                endAdornment:
                  postForm.title_post !== "" ? (
                    <IconButton
                      onClick={() =>
                        generateTenTitle(
                          postForm.title_post,
                          setValues,
                          postForm,
                          setOpenModal,
                          setEnancheTitle
                        )
                      }
                    >
                      <AutoFixHigh />
                    </IconButton>
                  ) : (
                    <></>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              margin="normal"
              fullWidth
              label="Slug Post"
              autoComplete="off"
              autoFocus
              value={postForm.slug_post || ""}
              helperText={touched.slug_post && errors.slug_post}
              error={Boolean(touched.slug_post && errors.slug_post)}
              onChange={(e) => changeHandler("slug_post", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              margin="normal"
              fullWidth
              label="Tag Title"
              autoComplete="off"
              autoFocus
              value={postForm.tag_title || ""}
              helperText={touched.tag_title && errors.tag_title}
              error={Boolean(touched.tag_title && errors.tag_title)}
              onChange={(e) => changeHandler("tag_title", e.target.value)}
              InputProps={{
                endAdornment:
                  postForm.title_post !== "" ? (
                    <IconButton
                      onClick={() =>
                        generateMetaTitle(
                          postForm.title_post,
                          setValues,
                          postForm
                        )
                      }
                    >
                      <AutoFixHigh />
                    </IconButton>
                  ) : (
                    <></>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <TextField
              margin="normal"
              fullWidth
              label="Meta Description"
              autoComplete="off"
              autoFocus
              value={postForm.meta_description || ""}
              helperText={touched.meta_description && errors.meta_description}
              error={Boolean(
                touched.meta_description && errors.meta_description
              )}
              onChange={(e) =>
                changeHandler("meta_description", e.target.value)
              }
              InputProps={{
                endAdornment:
                  postForm.title_post !== "" ? (
                    <IconButton
                      onClick={() =>
                        generateMetaDescription(
                          postForm.title_post,
                          setValues,
                          postForm
                        )
                      }
                    >
                      <AutoFixHigh />
                    </IconButton>
                  ) : (
                    <></>
                  ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              fullWidth
              label="Key Words"
              id="outlined-start-adornment"
              value={keyWords}
              onChange={(e) => setkeyWords(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      endIcon={<AddTwoTone />}
                      variant="contained"
                      color="info"
                      onClick={(e) => {
                        setValues({
                          ...postForm,
                          key_words: [...postForm.key_words, keyWords],
                        });
                        setkeyWords("");
                      }}
                    >
                      Aggiungi
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Card elevation={0}>
              <CardHeader
                avatar={
                  <Avatar
                    sx={{ bgcolor: (theme) => theme.palette.primary.light }}
                  >
                    <KeyTwoTone></KeyTwoTone>
                  </Avatar>
                }
                title="Lista della Key Words usate"
                subheader={postForm.link_post}
              />
              <CardContent>
                <Stack direction="row" spacing={1}>
                  {postForm.key_words?.map((res, i) => (
                    <Chip
                      label={res}
                      color="primary"
                      onDelete={(e) =>
                        setValues({
                          ...postForm,
                          key_words: [
                            ...postForm.key_words.filter(
                              (keyword) => keyword !== res
                            ),
                          ],
                        })
                      }
                    />
                  ))}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              margin="normal"
              fullWidth
              label="Chiave Primaria"
              autoComplete="off"
              autoFocus
              value={postForm.key_word_primary || ""}
              helperText={touched.key_word_primary && errors.key_word_primary}
              error={Boolean(
                touched.key_word_primary && errors.key_word_primary
              )}
              onChange={(e) =>
                changeHandler("key_word_primary", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <TextField
              fullWidth
              label="Testo html"
              id="htmlText"
              value={description || ""}
              multiline
              onBlur={() => setIsHtml(false)}
              onChange={(e) => {
                setDescription(e.target.value);
                setIsHtml(true);
              }}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Tiptap
              setDescription={setDescription}
              content={"description"}
              initial={post}
              wordCount={postForm.post_content.split(/\s+/).length}
              subheader={postForm.post_content.split(/\s+/).length * 2}
              isHtml={isHtml}
              description={description}
            ></Tiptap>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Stack direction={"row-reverse"} spacing={2}>
              {!id_post ? (
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<SaveAsTwoTone />}
                  onClick={() => handleSave(postForm)}
                >
                  Salva
                </Button>
              ) : type_domain === "clone" ? (
                <Button
                  variant="contained"
                  color="success"
                  startIcon={<SaveAsTwoTone />}
                  onClick={() => handleSave(postForm)}
                  disabled={post[0]?.state === "disabled"}
                >
                  Aggiorna
                </Button>
              ) : (
                <>
                  <Tooltip
                    title={`Questa azione salverà le modifiche SOLO in locale, e non sul sito Wp di destinazione`}
                  >
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<SaveAsTwoTone />}
                      onClick={() => handleSave(postForm)}
                      disabled={post[0]?.state === "disabled"}
                    >
                      Salva Modifiche
                    </Button>
                  </Tooltip>
                </>
              )}

              <Button
                variant="contained"
                color="warning"
                startIcon={<CancelTwoTone />}
                disabled={post[0]?.state === "disabled"}
                onClick={() =>
                  post.forEach((element) => {
                    setValues(element);
                  })
                }
              >
                Annulla
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<ArrowBack />}
                onClick={() => navigate(-1)}
              >
                indietro
              </Button>
              <Chip
                label={"Parole: " + postForm.post_content.split(/\s+/).length}
                icon={<NumbersTwoTone />}
                color="success"
                sx={{ fontSize: 15, fontWeight: "bold" }}
              />
              <Chip
                label={
                  "Token: " + postForm.post_content.split(/\s+/).length * 2
                }
                icon={<NumbersTwoTone />}
                color="info"
                sx={{ fontSize: 15, fontWeight: "bold" }}
              />
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <CircularProgress size="1rem" color="inherit" />
      )}
    </>
  );
};

export default Post;
