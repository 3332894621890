import axios from "axios";
import { errorInterceptor, setLoading } from "./apiUtility";

const instance = axios.create({
   //timeout: 1000,
  baseURL:process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_MODE:process.env.REACT_APP_PRO_MODE,
  headers: {
    'Access-Control-Allow-Origin':'*',
    "accept":"application/json",
    "Content-Type": "application/json", 
  },
});

instance.interceptors.request.use((request) => {
  setLoading(true);
  return request;
});

instance.interceptors.response.use(
  (response) => {
    setLoading(false);
    return response;
  },
  (error) => {
    errorInterceptor(error, 'Error');
    setLoading(false);
    return Promise.reject(error);
  }
);

export default instance;
