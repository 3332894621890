import createDataContext from "./createContext";
import { BaseService, TokenApi } from "../api";

const END_LOGIN = "end_login";
const LOGOUT = "logout";
const USER_PROFILE = "user_profile";
const UPDATE_DATA_CONTENT = "update_data_content";


const INITIAL_STATE = {
  user: null,
  userProfile:[],
  dataContent:[]
};


const authReducer = (state, action) => {
  switch (action.type) {
    case END_LOGIN: {
      const { user  } = action.payload;
      return { ...state, user};
    }
    case LOGOUT: {
      return { ...INITIAL_STATE };
    }
    case USER_PROFILE: {
      const { userProfile  } = action.payload;
      return { ...state, userProfile };
    }
    case UPDATE_DATA_CONTENT: {
      const { dataContent  } = action.payload;
      return { ...state, dataContent };
    }
    default: {
      return state;
    }
  }
};

const login = (dispatch) => async (loginForm, navigate) => {  
      const response = await BaseService.post(`/v1/login`, {
      username: loginForm.username,
      password: loginForm.password
    });
    if(response.data){
      const user = response.data.data;
      localStorage.setItem('token', response.data.data.access_token)
      if(localStorage.getItem("dataContent")){
        let dataContent = localStorage.getItem("dataContent")
        dispatch({ type: UPDATE_DATA_CONTENT, payload: { dataContent } });
      }else{
        let objVar = JSON.stringify([]);
        localStorage.setItem("dataContent", objVar)
      }
      dispatch({ type: END_LOGIN, payload: { user } });
      if(response.data.data.user.active){
        switch (response.data.data.role) {
          case "admin":
            navigate("/dashboard")
            break;
          default:
            navigate("/lista-domini")
            break;
        }
      }
    }
};

const logout = (dispatch) => async (navigate) => {
  navigate("/")
  localStorage.removeItem('token')
  dispatch({ type: LOGOUT });
};

/* const getUserProfile = (dispatch) => async (uuid) => {
  const response = await TokenApi.get(`/v1/user/${uuid}`,);
  if(response.data){
    dispatch({ type: USER_PROFILE, payload:{ userProfile : response.data.data }});
  }
}; */

const getTokenReg = (dispatch) => async (registerForm, navigate) => {
  const response = await BaseService.post(`/v1/request/register/user`, registerForm);
  if (response.data) {
    navigate("/email-confirm")
  }
};

const postConfirmAndCreateUser = (dispatch) => async (token, setSuccess,setLoading ) => {
  const response = await BaseService.get(`/v1/request/validate/user/${token}`);
  if (response.data) {
    setSuccess(true)
    setLoading(false)
  }
};

const updateDataContent = (dispatch) => async (data, setOpenGear) => {
  let dataContent =[]
  let content = localStorage.getItem("dataContent")
  let  oggettiRecuperati = JSON.parse(content);
  if (oggettiRecuperati?.length > 0){
    let dataConVar = oggettiRecuperati.filter((res)=> res.id !== data.id)
    dataContent = [...dataConVar, data]
    let objVar = JSON.stringify(dataContent);
    localStorage.setItem("dataContent", objVar)
    /* dispatch({ type: UPDATE_DATA_CONTENT, payload: { dataContent } }); */
  }else{
    let objVarNull = JSON.stringify([data]);
    localStorage.setItem("dataContent", objVarNull)
   /*  dispatch({ type: UPDATE_DATA_CONTENT, payload: { dataContent } }); */
  }
  setOpenGear(false)
};


export const { Provider, Context } = createDataContext(
  authReducer,
  {
    login,
    logout,
    /* getUserProfile, */
    getTokenReg,
    postConfirmAndCreateUser,
    updateDataContent
  }, // actions
  INITIAL_STATE // initial state
);
