import { Player } from "@lottiefiles/react-lottie-player";
import { ArrowBack } from "@mui/icons-material";
import { Button, Grid, Stack } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Copyright } from "../utilities";

const NotFound = () => {
  let navigate = useNavigate();

  return (
    <Grid container>
      <Grid
        item
        container
        direction={"row"}
        justifyContent={"center"}
        spacing={2}
      >
        <Grid item xs={12} md={12} lg={12}>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignContent={"center"}
            spacing={2}
          >
            <Button
              variant="text"
              color={"primary"}
              onClick={() => navigate(-1)}
              startIcon={<ArrowBack></ArrowBack>}
            >
              Torna indietro
            </Button>
          </Stack>
          <Player
            src="https://assets1.lottiefiles.com/packages/lf20_suhe7qtm.json"
            loop
            autoplay
            speed={2}
            style={{ height: "40%", width: "40%" }}
          />
        </Grid>
      <Copyright />
      </Grid>
    </Grid>
  );
};

export default NotFound;
