import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { Player } from "@lottiefiles/react-lottie-player";
import {
  PersonAddAlt1TwoTone,
  PersonTwoTone,
} from "@mui/icons-material";
import { menuVoice, titlePage } from "../utilities";
import { useNavigate } from "react-router-dom";
import { Avatar, Grid, Icon, Stack, Tooltip } from "@mui/material";

const ResponsiveAppBar = ({ child, windowMode }) => {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    navigate("/login");
  };

  const handleCloseNavMenu = (page) => {
    navigate(page.route);
    titlePage(page.name);
    setAnchorElNav(null);
  };

  return (
    <>
      <AppBar position="fixed" mb={2}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Player
                autoplay
                loop
                src="https://assets8.lottiefiles.com/packages/lf20_foww0tqq.json"
                style={{ height: "60px", width: "60px" }}
              ></Player>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar1"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {menuVoice.map((page) => (
                  <MenuItem
                    key={page.id + page.name}
                    onClick={() => handleCloseNavMenu(page)}
                  >
                    <Stack direction={"row"} spacing={2}>
                      <Icon>{page.icon}</Icon>
                      <Typography textAlign="center">{page.name}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Player
                autoplay
                loop
                src="https://assets8.lottiefiles.com/packages/lf20_foww0tqq.json"
                style={{ height: "60px", width: "60px" }}
              ></Player>
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {menuVoice.map((page) => (
                <Button
                  key={page.id + page.name}
                  onClick={() => handleCloseNavMenu(page)}
                  sx={{
                    my: 2,
                    color: "white",
                    borderBottom:
                      page.route === window.location.pathname ? 2 : 0,
                    borderColor: (theme) => theme.palette.secondary.main,
                  }}
                  startIcon={page.icon}
                >
                  {page.name}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {localStorage.getItem("token") ? (
                <Tooltip title="torna all' area riservata">
                <IconButton onClick={()=> navigate("/profilo")}>
                  <Avatar
                    sx={{ bgcolor: (theme) => theme.palette.success.main }}
                  >
                    <PersonTwoTone />
                  </Avatar>
                </IconButton>
                </Tooltip>
              ) : (
                <Button
                  onClick={handleOpenUserMenu}
                  startIcon={<PersonAddAlt1TwoTone />}
                  variant="text"
                  sx={{ color: "inherit" }}
                >
                  {" "}
                  Accedi
                </Button>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {windowMode ? (
        <Box
          mt={8}
          component="main"
          sx={{
            flexGrow: 1,
            width: "100%",
          }}
        >
          {child}
        </Box>
      ) : (
        <Grid container mt={10}>
          {child}
        </Grid>
      )}
    </>
  );
};
export default ResponsiveAppBar;
