import { Player } from "@lottiefiles/react-lottie-player";
import { LoginTwoTone } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonBase,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context";
import useForm, { isRequired } from "../hooks/useForm";
import { Copyright } from "../utilities";

const Login = () => {
  const navigate = useNavigate();
  const {
    /* state: { categorie }, */
    login,
  } = useContext(AuthContext);

  const validations = [
    ({ username }) =>
      isRequired(username) || { RagSoc: "*Ragione Sociale obbligatoria" },
    ({ password }) =>
      isRequired(password) || { RagSoc: "*Ragione Sociale obbligatoria" },
  ];

  const {
    values: loginForm,
    changeHandler,
    touched,
    errors,
    isValid,
  } = useForm(
    {
      username: "",
      password: "",
    },
    validations
  );

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        container
        xs={12}
        sm={6}
        md={6}
        lg={6}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          background:
            "linear-gradient(342deg, rgba(6,1,55,1) 70%, rgba(5,2,9,1) 98%)",
        }}
      >
        <Player
          src="https://assets2.lottiefiles.com/packages/lf20_kyzrg4ud.json"
          autoplay
          loop
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        {/*  <Stack direction={"row-reverse"} m={3}>
        <Button  onClick={()=>navigate(-1)} variant={"contained"} startIcon={<ArrowBackIosTwoTone/>}>Torna indietro</Button>
        </Stack> */}
        <Stack spacing={2} m={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1,
              m: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <Player
              src="https://assets8.lottiefiles.com/packages/lf20_RmBsp1fq3x.json"
              autoplay
              style={{ height: 150, width: 150 }}
              loop
            />
          </Box>

          <TextField
            margin="normal"
            fullWidth
            label="Utente"
            autoComplete="off"
            autoFocus
            value={loginForm.username || ""}
            helperText={touched.username && errors.username}
            error={Boolean(touched.username && errors.username)}
            onChange={(e) => changeHandler("username", e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="off"
            value={loginForm.password || ""}
            helperText={touched.password && errors.password}
            error={Boolean(touched.password && errors.password)}
            onChange={(e) => changeHandler("password", e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    type="submit"
                    disabled={!isValid}
                    variant="contained"
                    onClick={() => login(loginForm, navigate)}
                    endIcon={<LoginTwoTone />}
                  >
                    Entra
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <Copyright />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Login;
