import React from 'react';
import { 
    Alert, 
    AlertTitle, 
    Box, 
    Collapse, 
    List, 
    ListItem, 
    Paper 
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';

const Notifications = ({ notifications, closeNotification }) => {
  return (
    <Box sx={{
        position: 'fixed',
        right: 0,
        top: 30,
        maxWidth: 300,
        zIndex: 9999
    }}>
        <List>
            <TransitionGroup>
                {notifications.map((notif, index) => {
                    return <Collapse key={'transitioncollapse' + index}>
                        <ListItem sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Paper elevation={3}>
                                <Alert severity={notif.type} sx={{ zIndex: 1 }}
                                    onClose={() => { closeNotification(notif.id) }} index={index}>
                                    <AlertTitle sx={{ textTransform: "capitalize" }}>{notif.title}</AlertTitle>
                                    {notif.message}
                                </Alert>
                            </Paper>
                        </ListItem>
                    </Collapse>
                })}
            </TransitionGroup>
        </List>
    </Box>
  )
}

export default Notifications