import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Notifications, ResponsiveDrawer } from "./components";
import { useNotification } from "./hooks";
import { NotFound, Login, ListsDomain, Dashboard, Domain, Post, User, Process, Storage, FileRewrite, ProjectFile } from "./pages";
import "./App.css";
import TopKeyWords from "./pages/TopKeyWords";

const Main = () => {
  const [notifications, closeNotification] = useNotification();
  return (
    <>
      {notifications.length > 0 && (
        <Notifications
          notifications={notifications}
          closeNotification={closeNotification}
        />
      )}
      <Router>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Login/>}/>
          <Route path="/dashboard" element={<ResponsiveDrawer child={<Dashboard/>}/>}/>
          <Route path="/dashboard/user/:_uuid" element={<ResponsiveDrawer child={<User/>}/>}/>
          <Route path="/lista-domini" element={<ResponsiveDrawer child={<ListsDomain/>}/>}/>
          <Route path="/lista-domini/:id_domain" element={<ResponsiveDrawer child={<Domain/>}/>}/>
          <Route path="/profilo" element={<ResponsiveDrawer child={<ListsDomain/>}/>}/>
          <Route path="/post/:id_domain/:id_post/:id_sito/:uuid_domain" element={<ResponsiveDrawer child={<Post/>}/>}/>
          <Route path="/post/:id_domain/new" element={<ResponsiveDrawer child={<Post/>}/>}/>
          <Route path="/process/" element={<ResponsiveDrawer child={<Process/>}/>}/>
          <Route path="/process/:id" element={<ResponsiveDrawer child={<Process/>}/>}/>
          <Route path="/storage" element={<ResponsiveDrawer child={<Storage/>}/>}/>
          <Route path="/storage/top-keywords" element={<ResponsiveDrawer child={<TopKeyWords/>}/>}/>
          <Route path="/storage/file" element={<ResponsiveDrawer child={<FileRewrite/>}/>}/>
          <Route path="/storage/file/:id" element={<ResponsiveDrawer child={<ProjectFile/>}/>}/>
        </Routes>
      </Router>
    </>
  );
};

export default Main;
