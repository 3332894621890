import createDataContext from "./createContext";
import { TokenApi } from "../api";
import { sendNotification } from "../hooks/useNotification";
import { NotificationContent } from "../components";

const PROCESS_LIST = "process_list";
const PROCESS_DETAIL = "process_detail";

const PROJECT_LIST= "project_list";
const POST_PROJECT= "post_project";

const FILE_LIST= "file_list";

const INITIAL_STATE = {
  processList: [],
  process:{},
  project_files_list :[],
  files_list:[],
};


const processReducer = (state, action) => {
  switch (action.type) {
    case PROCESS_LIST: {
      const { processList  } = action.payload;
      return { ...state, processList };
    }
    case PROCESS_DETAIL: {
      const { process  } = action.payload;
      return { ...state, process };
    }
    case PROJECT_LIST: {
      const { project_files_list  } = action.payload;
      return { ...state, project_files_list };
    }
    case FILE_LIST: {
      const { files_list  } = action.payload;
      return { ...state, files_list };
    }
    default: {
      return state;
    }
  }
};


const getAllProcess = (dispatch) => async () => {
  const response = await TokenApi.get(`/v1/process`);
  if(response.data){
    dispatch({ type: PROCESS_LIST, payload:{ processList : response.data.data }});
  }
};

const getProcess= (dispatch) => async (_uuid) => {
  const response = await TokenApi.get(`/v1/process/${_uuid}`);
  if(response.data){
    dispatch({ type: PROCESS_DETAIL, payload:{ user : response.data.data }});
  }
};

const freeProcess= (dispatch) => async (id_process, id_post) => {
  const response = await TokenApi.put(`/v1/process/free/${id_process}/${id_post}`);
  if(response.data){
    sendNotification(
      <NotificationContent service={"Sblocco Processo"} />,
      "Processo Sbloccato!",
      "success",
      5
    );
    getAllProcess(dispatch)()
  }
};


const getProjectList= (dispatch) => async () => {
  const response = await TokenApi.get(`/v1/projects-file`);
  if(response.data){
    dispatch({ type: PROJECT_LIST, payload:{ project_files_list : response.data.data }});
  }
};

const getFileList= (dispatch) => async (projects_id) => {
  const response = await TokenApi.get(`/v1/project-file/${projects_id}`);
  if(response.data){
    dispatch({ type: FILE_LIST, payload:{ files_list : response.data.data }});
  }
};

const postProject= (dispatch) => async (data, setOpen) => {
  let payload = {"name_project": data}
  const response = await TokenApi.post(`/v1/projects-file`, payload);
  if(response.data){
    getProjectList(dispatch)()
    setOpen(false)
  }
};
const addFile= (dispatch) => async (projects_id, payload) => {
  const response = await TokenApi.post(`/v1/project-file/${projects_id}`, payload);
  if(response.data){
    getFileList(dispatch)(projects_id)
  }
};
const deleteFileRecord= (dispatch) => async (projects_id, payload) => {
  const response = await TokenApi.delete(`/v1/project-file/${projects_id}/${payload.id}`);
  if(response.data){
    getFileList(dispatch)(projects_id)
  }
};
const deleteProject= (dispatch) => async (projects_id) => {
  const response = await TokenApi.delete(`/v1/project-file/${projects_id}`);
  if(response.data){
    getProjectList(dispatch)()
  }
};

const generateFileRewrite= (dispatch) => async (projects_id, payload) => {
  const response = await TokenApi.put(`/v1/project-file/${projects_id}/${payload.id}/rewrite`);
  if(response.data){
      getFileList(dispatch)(projects_id)
      window.location.reload()
  }
};

export const { Provider, Context } = createDataContext(
  processReducer,
  {
    getAllProcess,
    getProcess,
    freeProcess,
    getProjectList,
    postProject,
    getFileList,
    addFile,
    deleteFileRecord,
    deleteProject,
    generateFileRewrite
  }, // actions
  INITIAL_STATE // initial state
);
