import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import {
  FaBold,
  FaHeading,
  FaItalic,
  FaLink,
  FaListOl,
  FaListUl,
  FaQuoteLeft,
  FaRedo,
  FaStrikethrough,
  FaUnderline,
  FaUndo,
  FaUnlink,
} from "react-icons/fa";
import Image from "@tiptap/extension-image";
import { useCallback, useEffect } from "react";
import Link from "@tiptap/extension-link";
import { Button, Chip, Stack } from "@mui/material";
import { capitalizeFirstLetter } from "../utilities";
import { NumbersTwoTone } from "@mui/icons-material";

const setLink = (editor) => {
  const previousUrl = editor.getAttributes("link").href;
  const url = window.prompt("URL", previousUrl);

  // cancelled
  if (url === null) {
    return;
  }

  // empty
  if (url === "") {
    editor.chain().focus().extendMarkRange("link").unsetLink().run();

    return;
  }

  // update link
  editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
};

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="menuBar">
      <div>
        <button
          onClick={() => setLink(editor)}
          className={editor.isActive("link") ? "is-active" : ""}
        >
          <FaLink />
        </button>
        <button
          onClick={() => editor.chain().focus().unsetLink().run()}
          disabled={!editor.isActive("link")}
        >
          <FaUnlink />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is_active" : ""}
        >
          <FaBold />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is_active" : ""}
        >
          <FaItalic />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={editor.isActive("underline") ? "is_active" : ""}
        >
          <FaUnderline />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={editor.isActive("strike") ? "is_active" : ""}
        >
          <FaStrikethrough />
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
          className={
            editor.isActive("heading", { level: 2 }) ? "is_active" : ""
          }
        >
          <FaHeading />
        </button>
        <button
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
          className={
            editor.isActive("heading", { level: 3 }) ? "is_active" : ""
          }
        >
          <FaHeading className="heading3" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is_active" : ""}
        >
          <FaListUl />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive("orderedList") ? "is_active" : ""}
        >
          <FaListOl />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          className={editor.isActive("blockquote") ? "is_active" : ""}
        >
          <FaQuoteLeft />
        </button>
      </div>
      <div>
        <button onClick={() => editor.chain().focus().undo().run()}>
          <FaUndo />
        </button>
        <button onClick={() => editor.chain().focus().redo().run()}>
          <FaRedo />
        </button>
      </div>
    </div>
  );
};

Image.configure({
  inline: true,
  //allowBase64: true,<img src="https://source.unsplash.com/8xznAGy4HcY/800x400" />
});

export const Tiptap = ({
  initial,
  setDescription,
  content,
  wordCount = 0,
  subheader,
  description,
  isHtml
}) => {

  const editor = useEditor({
    extensions: [StarterKit, Underline, Link, Image],
    content: `${description}`,
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      setDescription(html);
    },
  });
  

  useEffect(() => {
    if(isHtml){
      editor?.commands.setContent(description)
  
    }/* else{

    } */
  }, [description]);

  const addImage = useCallback(() => {
    const url = window.prompt("URL");

    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
  <div className="textEditor">
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"} p={1}>
        <Button onClick={addImage} variant="contained">Inserisci immagine</Button>
        <Chip label={"Parole: " + capitalizeFirstLetter(wordCount.toString())} icon={<NumbersTwoTone/>}  color="success" sx={{fontSize:15, fontWeight:"bold"}}  />
        <Chip label={"Token: "+ capitalizeFirstLetter(subheader.toString())} icon={<NumbersTwoTone/>}  color="info" sx={{fontSize:20, fontWeight:"bold"}} />
        </Stack>
        <MenuBar editor={editor} />
        <EditorContent editor={editor} />
  </div>
  );
};

export default Tiptap;
