import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";

export default function FormDialog({
  open,
  setOpen,
  sendMethod,
  text,
  title,
  formFiled,
  FormContent,
  ButtonDialog
}) {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
          {formFiled ? (
            <>
            {FormContent}
            </>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} variant="contained" color="secondary">
            Annulla
          </Button>
          {ButtonDialog}
        </DialogActions>
      </Dialog>
    </div>
  );
}
