import * as React from "react";
import Main from "./Main";
import { createTheme, ThemeProvider as MuiThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
/* import { itIT } from "@mui/material/locale";
import { itIT as itITGrid } from "@mui/x-data-grid"; */
import { it } from "date-fns/locale";
import {
  AuthContextProvider,
  DomainContextProvider,
  ProcessStateContextProvider,
  UserContextProvider,
} from "./context";
import moment from "moment";
import "moment/locale/it";

function App() {
  let theme = createTheme();
  theme = createTheme(
    {
      palette: {
        primary: {
          main: "#6b01dc",
        },
        secondary: {
          main: "#ce00b4",
        },
        text: {
          primary: "rgba(0,0,0,0.87)",
        },
        success: {
          dark: "#007717",
          main: "#28a745",
          light: "#64da73",
          contrastText: "#fff",
        },
        warning: {
          dark: "#b28704",
          main: "#E0B812",
          light: "#ffcd38",
        },
        error: {
          dark: "#b80000",
          main: "#f40400",
          light: "#ff5834",
          contrastText: "#fff",
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              border: "0px",
            },
          },
        },
        MuiCardHeader: {
          styleOverrides: {
            root: {
              color: "black",
            },
          },
        },
      },
    },
   /*  itIT,
    itITGrid */
  );
  React.useEffect(() => {
    moment().locale("it");
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
      <MuiThemeProvider theme={theme}>
        <ProcessStateContextProvider>
          <UserContextProvider>
            <AuthContextProvider>
              <DomainContextProvider>
                <Main></Main>
              </DomainContextProvider>
            </AuthContextProvider>
          </UserContextProvider>
        </ProcessStateContextProvider>
      </MuiThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
