import { Add, Delete, VisibilityTwoTone } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { FormDialog } from "../components";
import { DomainContext, ProcessStateContext } from "../context";
import { useNavigate } from "react-router-dom";

const FileRewrite = () => {
  const [open, setOpen] = useState(false);
  const [name_project, setNameProject] = useState("");
  const [pageSize, setPageSize] = React.useState(25);

  const navigate = useNavigate()

  useEffect(() => {
    setNameProject("");
  }, [open]);

  const {
    state: { project_files_list },
    getProjectList,
    postProject,
    deleteProject
  } = useContext(ProcessStateContext);

  const {
    setTitle,
  } = useContext(DomainContext);

  useEffect(() => {
    getProjectList();
  }, []);

  const FiledFormInsertProject = (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          autoFocus
          margin="dense"
          id="name_project"
          label="Nome Progetto"
          type="site"
          fullWidth
          variant="standard"
          value={name_project}
          onChange={(e) => setNameProject(e.target.value)}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container>
      <FormDialog
        formFiled={true}
        FormContent={FiledFormInsertProject}
        open={open}
        setOpen={setOpen}
        ButtonDialog={
          <Button onClick={() => postProject(name_project, setOpen)}>
            Crea Progetto
          </Button>
        }
        text={"Crea un nuovo progetto per il rewrite dei file di testo"}
        title={"Riscrivi post da file txt "}
      ></FormDialog>
      <Grid item xs={12} md={12} lg={12} mb={2}>
        <ButtonGroup variant="contained">
          <Button
            startIcon={<Add />}
            onClick={() => setOpen(!open)}
            color="primary"
          >
            Aggiungi Progetto
          </Button>
        </ButtonGroup>
      </Grid>

      <DataGrid
        autoHeight
        rows={project_files_list}
        columns={[
          {
            field: "",
            headerName: "Azioni",
            width: 150,
            renderCell: ({row}) => (
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                p={1}
              >
                <IconButton
                  size="small"
                  onClick={()=>deleteProject(row.id)}
                >
                  <Delete color="error" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    setTitle(row.name_project)
                    navigate(`${row.id}`);
                  }}
                >
                  <VisibilityTwoTone color="info" />
                </IconButton>
              </Stack>
            ),
          },
          { field: "name_project", flex: 1, headerName: "Nome progetto" },
          {
            field: "description_name_project",
            flex: 1,
            headerName: "Descrizione progetto",
          },
        ]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        disableSelectionOnClick
        disableRowSelectionOnClick={false}
      />
    </Grid>
  );
};

export default FileRewrite;
