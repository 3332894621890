import { DataGrid, useGridApiRef } from "@mui/x-data-grid";
import React, { useContext, useEffect } from "react";
import { DomainContext } from "../context";
import clsx from "clsx";
import { Link, MenuItem, Stack, TextField } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { useParams } from "react-router-dom";

const Storage = () => {
  const {
    state: { listsDomainKeywords, uniqueDomains },
    getListDomainKey,
    filterListKeywords,
    filterListKeywordsDomainOnly,
    getListDomainForSelect,
    resetDomainKeywords,
  } = useContext(DomainContext);

  const { action } = useParams();
  const apiRef = useGridApiRef();

  const [pageSize, setPageSize] = React.useState(10);

  const columns = [
    {
      field: "domain",
      headerName: "Dominio",
      flex:1,
      cellClassName: (params) => {
        if (params.row.duplicate_across_posts == null) {
          return clsx("super-app", {
            negative: true,
          });
        } else {
          return clsx("super-app", {
            positive: params.row.duplicate_across_posts.length > 0,
          });
        }
      },
    },
    {
      field: "keywords",
      headerName: "keywords",
      flex:1,
      cellClassName: (params) => {
        if (params.row.duplicate_across_posts == null) {
          return clsx("super-app", {
            negative: true,
          });
        } else {
          return clsx("super-app", {
            positive: params.row.duplicate_across_posts.length > 0,
          });
        }
      },
    },
    {
      field: "link_post",
      headerName: "URL",
      flex:1,
      cellClassName: (params) => {
        if (params.row.duplicate_across_posts == null) {
          return clsx("super-app", {
            negative: true,
          });
        } else {
          return clsx("super-app", {
            positive: params.row.duplicate_across_posts.length > 0,
          });
        }
      },
      renderCell: ({ row }) => (
        <Link href={row.link_post} underline="hover"  target="_blank" rel="noopener noreferrer">
          {row.link_post}
        </Link>
      ),
    },
    {
      field: "totDuplicati",
      headerName: "Tot. Duplicati",
      filterable: true,
      sortable: true,
      renderCell: (params) => {
        const number =
          params.row.duplicate_across_posts == null
            ? 0
            : params.row.duplicate_across_posts.length;
        return (
          <Stack direction={"row"} justifyContent={"center"}>
            {number}
          </Stack>
        );
      },
      cellClassName: (params) => {
        if (params.row.duplicate_across_posts == null) {
          return clsx("super-app", {
            negative: true,
          });
        } else {
          return clsx("super-app", {
            positive: params.row.duplicate_across_posts.length > 0,
          });
        }
      },
    },
    {
      field: "duplicate_across_posts",
      headerName: "Keys Duplicate",
      flex:1,
      cellClassName: (params) => {
        if (params.row.duplicate_across_posts == null) {
          return clsx("super-app", {
            negative: true,
          });
        } else {
          return clsx("super-app", {
            positive: params.row.duplicate_across_posts.length > 0,
          });
        }
      },
    },
    {
      field: "targetkw",
      headerName: "targetkw",
      flex:1,
      cellClassName: (params) => {
        if (params.row.duplicate_across_posts == null) {
          return clsx("super-app", {
            negative: true,
          });
        } else {
          return clsx("super-app", {
            positive: params.row.duplicate_across_posts.length > 0,
          });
        }
      },
    },
    {
      field: "external_links",
      headerName: "external_links",
      flex:1,
      cellClassName: (params) => {
        if (params.row.duplicate_across_posts == null) {
          return clsx("super-app", {
            negative: true,
          });
        } else {
          return clsx("super-app", {
            positive: params.row.duplicate_across_posts.length > 0,
          });
        }
      },
    },
  ];

  useEffect(() => {
    getListDomainKey();
    getListDomainForSelect();
    apiRef.current.autosizeColumns({
      includeHeaders: true,
      includeOutliers: true,
    })
    return () => {
      resetDomainKeywords();
    };
  }, []);

 /*  console.log('listsDomainKeywords: ', listsDomainKeywords); */
  return (
    <>
      {/* <Stack direction={"row"} spacing={3} justifyContent={"center"}>
        <TextField
          margin="normal"
          fullWidth
          label="Ricerca"
          autoComplete="off"
          autoFocus
          onChange={(e) =>
            filterListKeywords(e.target.value, listsDomainKeywords)
          }
        />
      </Stack> */}
      <Stack spacing={2} justifyContent={"center"} mb={2}>
      <TextField
          margin="normal"
          fullWidth
          label="Ricerca"
          size="small"
          autoComplete="off"
          autoFocus
          onChange={(e) =>
            filterListKeywords(e.target.value, listsDomainKeywords)
          }
        />
        <TextField
          select
          fullWidth
          margin="normal"
          size="small"
          label="Domini"
          autoComplete="off"
          autoFocus
          onChange={(e) => {
            filterListKeywordsDomainOnly(e.target.value, listsDomainKeywords);
          }}
        >
          <MenuItem value={""}>Nessun Dominio</MenuItem>
          {uniqueDomains.map((option, i) => (
            <MenuItem key={i} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <DataGrid
        sx={{
          "& .super-app.positive": {
            backgroundColor: red[100],
            borderColor: red[400],
          },
          "& .super-app.negative": {
            backgroundColor: blue[100],
            borderColor: blue[400],
          },
        }}
        autoHeight
        rows={listsDomainKeywords.map(row => ({
          ...row,
          totDuplicati: row.duplicate_across_posts ? row.duplicate_across_posts.length : 0,
        }))}
        columns={columns}
        autosizeOnMount 
        pageSize={pageSize}
        initialState={{
          sorting: {
            sortModel: [{ field: "duplicate_across_posts", sort: "desc" }],
          },
        }}
        apiRef={apiRef}
        getRowId={(params) => params.id_post}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        disableSelectionOnClick
        disableRowSelectionOnClick={false}
      />
    </>
  );
};

export default Storage;
