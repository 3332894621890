import {
  Backdrop,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext, DomainContext } from "../context";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Add,
  ArrowBack,
  AutoAwesome,
  Circle,
  DeleteForever,
  DeleteOutline,
  DoneAllTwoTone,
  Download,
  LocalOffer,
  PagesTwoTone,
  PostAdd,
  PostAddTwoTone,
  SelectAllTwoTone,
  SmartToy,
  TagTwoTone,
  UpdateTwoTone,
  VisibilityTwoTone,
  WarningTwoTone,
} from "@mui/icons-material";
import { FormDialog } from "../components";
import moment from "moment";
import useForm, { isRequired } from "../hooks/useForm";

const Domain = () => {
  const { id_domain } = useParams();
  const {
    state: { domain_post, domain_info, categories },
    getDomain,
    getPostsFromDomain,
    cleanBrandTargetMassive,
    cleanTagMassive,
    setTitle,
    cleanTextMassive,
    checkPostFree,
    checkPostFreeTwo,
    uploadPostOnWpSiteUpdated,
    UpdatePostWpMassive,
    uploadPostOnWpSiteMultiSelect,
    takeWpCategoriesByCred,
    AddNewCategoriesOnWpByCred,
    replaceTextMassive,
    deletePostByUuid,
  } = useContext(DomainContext);

  const { updateDataContent } = useContext(AuthContext);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsModel, setSelectedRowsModel] = useState([]);
  const [openGear, setOpenGear] = useState(false);
  const [valuePostFont, setValuePostFont] = useState("posts");
  const [modeDowload, setModeDowload] = useState({
    posts: true,
    pages: false,
  });
  const [openCategoriesNew, setOpenCategoriesNew] = useState(false);
  const [categoriesAdd, setcategoriesAdd] = useState({
    description: "",
    name: "",
    taxonomy: "category",
  });
  const [textOpenGear, setTextOpenGear] = useState("");
  const [IsRewriteDomain, setIsRewriteDomain] = useState(false);
  const [openBk, setOpenBk] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [pageSize, setPageSize] = React.useState(10);
  const [openTextRemove, setOpenTextRemove] = React.useState(false);
  const [textToRemove, setTextToRemove] = React.useState("");

  let dataContent = JSON.parse(localStorage.getItem("dataContent"));

  const navigate = useNavigate();

  const validations = [
    ({ user }) => isRequired(user) || { user: "*Username mancante" },
    ({ password }) =>
      isRequired(password) || { password: "*Devi inserire la password" },
    ({ url }) => isRequired(url) || { url: "*Manca url del sito wp" },
  ];

  const {
    values: FormWpInvio,
    changeHandler,
    touched,
    errors,
    setValues,
    isValid,
    ValidateValue,
  } = useForm(
    {
      user: "",
      password: "",
      categoria: "",
      url: "",
      uuid_domain: "",
      user_domain_rewrite: "",
      pass_domain_rewrite: "",
    },
    validations
  );

  const columns = [
    {
      field: "state",
      headerName: "Stato",
      width: 100,
      renderCell: (params) =>
        params.row.state === "enabled" ? (
          <Circle color="success" fontSize="xs"></Circle>
        ) : (
          <>
            <CircularProgress size={20} />
          </>
        ),
    },
    {
      field: "is_pages",
      headerName: "Fonte",
      width: 150,
      renderCell: (params) =>
        params.row.is_pages && !params.row.is_posts ? (
          <Chip
            icon={<PagesTwoTone />}
            label="Pages"
            color="success"
            variant="outlined"
          />
        ) : (
          <Chip
            icon={<PostAddTwoTone />}
            label="Posts"
            color="info"
            variant="outlined"
          />
        ),
    },
    {
      field: "data_aggiornamento",
      headerName: "Ultimo aggiornamento",
      width: 300,
      renderCell: (params) => moment(params.row.data_aggiornamento).calendar(),
    },
    {
      field: "chip_upload",
      headerName: "Stato Upload WP",
      width: 200,
      renderCell: ({ row }) =>
        row.wp_upload?.length > 0 ? (
          <Tooltip title={row.wp_upload}>
            <Chip
              icon={<DoneAllTwoTone />}
              label={"Inviato a sito/i WP"}
              color="success"
              variant="filled"
            />
          </Tooltip>
        ) : (
          <Chip
            icon={<WarningTwoTone />}
            label="Non inviato a WP"
            color="warning"
            variant="filled"
          />
        ),
    },
    {
      field: "wp_upload",
      headerName: "Lista siti wp",
      width: 200,
    },
    {
      field: "",
      headerName: "Azioni",
      width: 150,
      /* width: domain_info[0]?.type_domain === "clone" ? 720 : 400, */
      renderCell: (params) =>
        domain_info[0]?.type_domain === "clone" ? (
          <Stack direction={"row"} spacing={2}>
            {/*  <ButtonGroup aria-label="text button group" size="small"> */}
            <IconButton
              disabled={params.row.state === "disabled"}
              onClick={() =>
                checkPostFreeTwo(
                  id_domain,
                  params.row.id,
                  navigate,
                  `/post/${params.row.lists_domain_id}/${params.row.id}/${domain_info[0]?.url_domain}/${id_domain}`
                )
              }
            >
              <VisibilityTwoTone />
            </IconButton>
            <IconButton
              disabled={params.row.state === "disabled"}
              onClick={() =>
                deletePostByUuid(params.row._uuid, domain_info[0]._uuid)
              }
            >
              <DeleteOutline color="error" />
            </IconButton>
          </Stack>
        ) : (
          <Stack direction={"row"} spacing={1}>
            <IconButton
              disabled={params.row.state === "disabled"}
              onClick={() =>
                checkPostFreeTwo(
                  id_domain,
                  params.row.id,
                  navigate,
                  `/post/${params.row.lists_domain_id}/${params.row.id}/${domain_info[0]?.url_domain}/${id_domain}`
                )
              }
            >
              <VisibilityTwoTone />
            </IconButton>
            <Divider orientation="vertical" flexItem></Divider>
            <Chip
              mt={1}
              /* disabled={params.row.state === "disabled"} */
              label="AI prompt"
              clickable
              disabled
              icon={<SmartToy color="info" />}
              onClick={() => ""}
            />
            <Tooltip title="Questa azione aggiorna contemporaneamente i dati su questo sito e sul sito WordPress di destinazione">
              <Chip
                label="Aggiorna Post"
                clickable
                icon={<UpdateTwoTone color="info" />}
                onClick={() =>
                  uploadPostOnWpSiteUpdated(
                    params.row.lists_domain_id,
                    {
                      post_content: params.row.post_content,
                      slug_post: params.row.slug_post,
                      id_post: params.row.id_post,
                      title_post: params.row.title_post,
                      link_post: params.row.link_post,
                    },
                    id_domain
                  )
                }
              />
            </Tooltip>
          </Stack>
        ),
    },
    {
      field: "title_post",
      headerName: "Titolo post",
      width: 500,
    },
    {
      field: "post_content",
      headerName: "Contenuto",
      width: 700,
    },
  ];

  const openMenu = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (id_domain) {
      let dataCon = dataContent?.find((res) => res.id === id_domain);
      getDomain(id_domain, setOpenBk);
      setOpenBk(true);
      setTitle("Elenco post");
      setValues({
        ...FormWpInvio,
        user: dataCon ? dataCon.user : "",
        password: dataCon ? dataCon.password : "",
        categoria: "",
        url: dataCon ? dataCon.url : "",
        uuid_domain: id_domain,
        user_domain_rewrite: "",
        pass_domain_rewrite: "",
      });
      ValidateValue(validations, {
        ...FormWpInvio,
        user: dataCon ? dataCon.user : "",
        password: dataCon ? dataCon.password : "",
        categoria: "",
        url: dataCon ? dataCon.url : "",
        uuid_domain: id_domain,
        user_domain_rewrite: "",
        pass_domain_rewrite: "",
      });
    }
  }, [id_domain]);

  useEffect(() => {
    setTimeout(() => {
      setOpenBk(false);
    }, 3000);
  }, [domain_post, domain_info]);

  useEffect(() => {
    setTimeout(() => {
      setOpenBk(false);
    }, 80000);
  }, [openBk]);

  const ButtonDialogDomain = (
    <>
      <Button
        onClick={() => {
          getPostsFromDomain(
            domain_info[0]?.id,
            domain_info[0]?.url_domain,
            id_domain,
            setOpenBk,
            modeDowload
          );
          setOpen(false);
        }}
        variant="contained"
      >
        Scarica
      </Button>
    </>
  );

  const FormContentChooseMode = (
    <FormControl width="100%">
      <FormLabel id="demo-row-radio-buttons-group-label">
        Fonte dei Posts
      </FormLabel>
      <Typography>Esempio:</Typography>
      <Typography>(https://dominio.it/wp-json/wp/v2/posts)</Typography>
      <Typography>
        -----------------------------------------------------
      </Typography>
      <Typography> </Typography>
      <Typography>(https://dominio.it/wp-json/wp/v2/pages)</Typography>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={valuePostFont}
        onChange={(event) => {
          if (event.target.value === "posts") {
            setModeDowload({ posts: true, pages: false });
          } else {
            setModeDowload({ posts: false, pages: true });
          }
          setValuePostFont(event.target.value);
        }}
      >
        <FormControlLabel value="posts" control={<Radio />} label="Post" />
        <FormControlLabel value="pages" control={<Radio />} label="Pages" />
        {/* <FormControlLabel value="other" control={<Radio />} label="Other" /> */}
        {/* <FormControlLabel
          value="disabled"
          disabled
          control={<Radio />}
          label="other"
        /> */}
      </RadioGroup>
    </FormControl>
  );
  const changeHandlerCategories = (key, value) => {
    setcategoriesAdd({ ...categoriesAdd, [key]: value });
  };

  const newCategoriesDialog = (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          autoFocus
          margin="dense"
          id="site_wp"
          label="Nome - Categoria"
          type="site"
          fullWidth
          variant="standard"
          value={categoriesAdd.name}
          onChange={(e) => changeHandlerCategories("name", e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          autoFocus
          margin="dense"
          id="site_wp"
          label="Descrizione - Categoria"
          type="site"
          fullWidth
          variant="standard"
          value={categoriesAdd.description}
          onChange={(e) =>
            changeHandlerCategories("description", e.target.value)
          }
        />
      </Grid>
    </Grid>
  );

  const newCategoriesDialogButton = (
    <Button
      onClick={() =>
        AddNewCategoriesOnWpByCred(
          FormWpInvio,
          categoriesAdd,
          setOpenCategoriesNew
        )
      }
      variant="contained"
    >
      Crea Categoria
    </Button>
  );

  const FormDialogGearAction = (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          autoFocus
          margin="dense"
          id="site_wp"
          label="Indirizzo sito web"
          type="site"
          disabled={IsRewriteDomain}
          fullWidth
          variant="standard"
          value={FormWpInvio.url}
          helperText={touched.url && errors.url}
          error={Boolean(touched.url && errors.url)}
          onChange={(e) => changeHandler("url", e.target.value)}
        />
      </Grid>
      {!IsRewriteDomain ? (
        <>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="username wp"
              type="text"
              fullWidth
              variant="standard"
              value={FormWpInvio.user}
              helperText={touched.user && errors.user}
              error={Boolean(touched.user && errors.user)}
              onChange={(e) => changeHandler("user", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TextField
              autoFocus
              margin="dense"
              id="pass"
              label="Password delle applicazioni"
              type="password"
              fullWidth
              variant="standard"
              value={FormWpInvio.password}
              helperText={touched.password && errors.password}
              error={Boolean(touched.password && errors.password)}
              onChange={(e) => changeHandler("password", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12} mt={2}>
            <FormControl
              fullWidth
              size="small"
              variant="filled"
              disabled={!isValid}
            >
              <InputLabel id="demo-simple-select-label">
                Categorie Esistenti
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={FormWpInvio.categoria}
                label="Categorie Esistenti"
                disabled={!isValid}
                onFocus={() => takeWpCategoriesByCred(FormWpInvio)}
                defaultValue={-1}
                onChange={(e) => changeHandler("categoria", e.target.value)}
              >
                {categories?.length === 0 && isValid ? (
                  <MenuItem value={-1}>
                    <CircularProgress size={20} color="inherit" />
                  </MenuItem>
                ) : (
                  <MenuItem value={-1}>Seleziona Categoria</MenuItem>
                )}
                {categories?.map((res, idx) => (
                  <MenuItem key={idx} value={res.id}>
                    {res.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Stack direction={"row"} spacing={2} mt={1}>
            <Button
              disabled={!isValid}
              onClick={() => setOpenCategoriesNew(!openCategoriesNew)}
              variant="contained"
              startIcon={<Add></Add>}
              size="small"
            >
              Aggiungi Categoria
            </Button>
          </Stack>
        </>
      ) : (
        <>
          <>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus
                margin="dense"
                id="username"
                label="username"
                type="text"
                disabled
                fullWidth
                variant="standard"
                value={FormWpInvio.user_domain_rewrite}
                helperText={
                  touched.user_domain_rewrite && errors.user_domain_rewrite
                }
                error={Boolean(
                  touched.user_domain_rewrite && errors.user_domain_rewrite
                )}
                onChange={(e) =>
                  changeHandler("user_domain_rewrite", e.target.value)
                }
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus
                margin="dense"
                id="pass"
                disabled
                label="password"
                type="password"
                fullWidth
                variant="standard"
                value={FormWpInvio.pass_domain_rewrite}
                helperText={
                  touched.pass_domain_rewrite && errors.pass_domain_rewrite
                }
                error={Boolean(
                  touched.pass_domain_rewrite && errors.pass_domain_rewrite
                )}
                onChange={(e) =>
                  changeHandler("pass_domain_rewrite", e.target.value)
                }
              />
            </Grid>
          </>
        </>
      )}
    </Grid>
  );

  const ButtonDialogGear = (
    <>
      <Button
        disabled={!isValid}
        onClick={() => {
          if (IsRewriteDomain) {
            /* console.log("IsRewriteDomain devo mettere il metodo"); */
          } else {
            uploadPostOnWpSiteMultiSelect(
              FormWpInvio,
              FormWpInvio.uuid_domain,
              selectedRows
            );
            updateDataContent(
              {
                id: FormWpInvio.uuid_domain,
                user: FormWpInvio.user,
                password: FormWpInvio.password,
                url: FormWpInvio.url,
              },
              setOpenGear
            );
          }
        }}
        variant="contained"
      >
        {IsRewriteDomain ? "Aggiorna Post" : "Invia Post"}
      </Button>
    </>
  );

  const DialogTextDelete = (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          autoFocus
          margin="dense"
          id="text_remove"
          label="Testo da Eliminare"
          type="site"
          fullWidth
          multiline
          variant="standard"
          value={textToRemove}
          onChange={(e) => setTextToRemove(e.target.value)}
        />
      </Grid>
    </Grid>
  );

  const ButtonDialogTextRemove = (
    <>
      <Button
        disabled={textToRemove === "" ? true : false}
        variant="contained"
        onClick={() =>
          replaceTextMassive(
            domain_post,
            textToRemove,
            domain_info[0]._uuid,
            setOpenTextRemove
          )
        }
      >
        Elimina Testo
      </Button>
    </>
  );

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBk}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <FormDialog
        formFiled={true}
        FormContent={FormContentChooseMode}
        open={open}
        setOpen={setOpen}
        ButtonDialog={ButtonDialogDomain}
        text={"Scarica i post sul tuo db"}
        title={"Post search"}
      ></FormDialog>
      <ButtonGroup variant="contained" color="warning">
        <Button
          color="info"
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
        >
          Torna Indietro
        </Button>
        <Tooltip
          title={
            domain_post?.length > 0
              ? `Scarica sulla piattaforma i nuovi post e i post esistenti aggiornati direttamente dal sito ${domain_info[0]?.url_domain}`
              : `Verranno scaricati i post presenti dal sito ${domain_info[0]?.url_domain}`
          }
        >
          <Button endIcon={<Download />} onClick={() => setOpen(!open)}>
            {domain_post?.length > 0
              ? "Scarica Post Aggiornati "
              : "Scarica i Nuovi post"}
          </Button>
        </Tooltip>
        <Button
          id="basic-button"
          disabled={!selectedRows.length > 0}
          aria-controls={openMenu ? "basic-menu" : undefined}
          aria-haspopup="true"
          color="success"
          aria-expanded={openMenu ? "true" : undefined}
          onClick={handleClick}
          endIcon={<SelectAllTwoTone />}
        >
          Post Selezionati{" "}
          {" " + selectedRows.length > 0 ? "(" + selectedRows.length + ")" : ""}
        </Button>

        {domain_info[0]?.type_domain === "clone" ? (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <Divider>
              <Chip label="Invio dati" />
            </Divider>
            <MenuItem>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<AutoAwesome></AutoAwesome>}
                onClick={() => {
                  setOpenGear(true);
                  handleClose();
                }}
              >
                Invia Post a sito Wp
              </Button>
            </MenuItem>
            <Divider>
              <Chip label="Azioni" />
            </Divider>
            <MenuItem>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  cleanTagMassive(
                    selectedRows,
                    id_domain,
                    domain_info[0].url_domain
                  );
                  handleClose();
                }}
                startIcon={<TagTwoTone />}
              >
                Elimina tag
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                variant="contained"
                color="info"
                fullWidth
                onClick={() => {
                  cleanTextMassive(
                    selectedRows,
                    id_domain,
                    domain_info[0].url_domain
                  );
                  handleClose();
                }}
                startIcon={<SmartToy />}
              >
                Modifica Contenuto
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                variant="contained"
                color="error"
                fullWidth
                onClick={() => {
                  cleanBrandTargetMassive(
                    selectedRows,
                    id_domain,
                    domain_info[0].url_domain
                  );
                  handleClose();
                }}
                startIcon={<LocalOffer />}
              >
                Elimina Brand Post
              </Button>
            </MenuItem>
          </Menu>
        ) : (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem>
              <Chip
                label="AI prompt"
                clickable
                disabled
                icon={<SmartToy color="info" />}
                onClick={() => ""}
              />
            </MenuItem>
            <MenuItem>
              <Chip
                label="Aggiorna Post"
                clickable
                icon={<UpdateTwoTone color="info" />}
                onClick={() =>
                  UpdatePostWpMassive(
                    selectedRows,
                    id_domain,
                    domain_info[0].url_domain
                  )
                }
              />
            </MenuItem>
          </Menu>
        )}
        {domain_info[0]?.type_domain === "clone" ? (
          <Button
            color="info"
            endIcon={<PostAdd />}
            onClick={() => navigate(`/post/${id_domain}/new`)}
          >
            Aggiungi Post
          </Button>
        ) : (
          <></>
        )}
        <Button
          color="error"
          endIcon={<DeleteForever />}
          onClick={() => setOpenTextRemove(!openTextRemove)}
        >
          Cancella Testo
        </Button>
      </ButtonGroup>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <div style={{ minWidth: 600 }}>
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            rows={domain_post}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 25, 50, 100]}
            autoHeight
            disableSelectionOnClick
            onRowSelectionModelChange={(ids, params) => {
              const selectedIDs = new Set(ids);
              const selectedRows = domain_post.filter((row) =>
                selectedIDs.has(row.id)
              );
              setSelectedRows(selectedRows);
              setSelectedRowsModel(ids);
              let id_post =
                ids.length > 1
                  ? ids[ids.length - 1]
                  : ids.length === 1
                  ? ids[0]
                  : null;
              checkPostFree(
                id_domain,
                id_post,
                selectedIDs,
                setSelectedRows,
                domain_post,
                ids,
                setSelectedRowsModel
              );
            }}
            selectionModel={selectedRowsModel}
          />
        </div>
        <FormDialog
          FormContent={DialogTextDelete}
          ButtonDialog={ButtonDialogTextRemove}
          formFiled={true}
          open={openTextRemove}
          setOpen={setOpenTextRemove}
          text={"Inserisci il testo da Eliminare"}
        ></FormDialog>

        <FormDialog
          FormContent={FormDialogGearAction}
          ButtonDialog={ButtonDialogGear}
          formFiled={true}
          open={openGear}
          setOpen={setOpenGear}
          text={textOpenGear}
        ></FormDialog>
        <FormDialog
          FormContent={newCategoriesDialog}
          ButtonDialog={newCategoriesDialogButton}
          formFiled={true}
          open={openCategoriesNew}
          setOpen={setOpenCategoriesNew}
          text={"Crea una nuova categoria"}
        ></FormDialog>
      </div>
    </>
  );
};

export default Domain;
