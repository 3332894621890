import {
  Add,
  AdminPanelSettingsTwoTone,
  CopyAll,
  DashboardTwoTone,
  Done,
  SendTimeExtensionRounded,
  Update,
  VerifiedUser,
  VideoStableRounded,
  WrongLocationOutlined,
} from "@mui/icons-material";
import { Card, CardContent, Chip, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import jwt_decode from "jwt-decode";

export const decodeJwt =()=>{
  let decoded = jwt_decode(localStorage.getItem("token"));
  return decoded
}

export const menuVoice = [
  {
    id: 0,
    name: "Dashboard",
    descrizione: "dashboard",
    icon: <AdminPanelSettingsTwoTone />,
    route: "/dashboard",
  },
];

export const menuVoiceAdmin = [
  {
    id: 0,
    name: "dashboard",
    descrizione: "dashboard",
    icon: <DashboardTwoTone />,
    route: "/dashboard",
    isAdmin:true
  },
  {
    id: 1,
    name: "lista domini",
    descrizione: "lista-domini",
    icon: <AdminPanelSettingsTwoTone />,
    route: "/lista-domini",
    isAdmin:false
  },
];

export function titlePage(pagina) {
  if (pagina !== "dashboard") {
    document.title = "| " + pagina;
  } else {
    document.title = "| Dashboard";
  }
}

export function Copyright(props) {
  return (
    <Container maxWidth="xl">
      <Card elevation={0}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <Card>
                <Typography></Typography>
              </Card>
            </Grid>
          </Grid>
          <Box textAlign="center">
            AUTOMA Srl P. Iva: IT16208401006 Via Pietro Adami 83 - 00168 Roma
            Copyright © {new Date().getFullYear()}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function isWordpress(value) {
  switch (value) {
    case "is_wordpress":
      return(
      <Chip
        icon={<Done/>}
        label="Wordpress"
        color="success" 
      />);
    case "not_wordpress":
      return (
        <Chip
          color="error"
          icon={<WrongLocationOutlined/>}
          label="Not Wordpress"
        />
      );
    default:
      break;
  }
}

export function TypeModeDomain(value) {
  switch (value) {
    case "clone":
      return(
      <Chip
        icon={<CopyAll/>}
        label="Clonazione"
        color="info" 
        style={{backgroundColor:'blueviolet'}}
      />);
    case "update":
      return (
        <Chip
          color="warning"
          icon={<Update/>}
          label="Aggiornamento"
          style={{backgroundColor:'lavender', color:"black"}}
        />
      );
      case "created":
      return (
        <Chip
          color="error"
          icon={<Add/>}
          label="Creazione"
        />
      );
    default:
      break;
  }
}


export function TypeCategories(value) {
  switch (value) {
    case "enabled":
      return(
      <Chip
        icon={<VerifiedUser/>}
        label="Proprietario"
        color="info" 
        /* style={{backgroundColor:'blueviolet'}} */
      />);
    case "disabled":
      return (
        <Chip
          color="error"
          icon={<VideoStableRounded/>}
          label="Competitor"
        />
      );
    default:
      break;
  }
}



export function rimuoviCaratteriSpeciali(testo) {
  // Utilizza una regex per catturare tutti i caratteri non alfanumerici
  let regex = /[^a-zA-Z0-9-]/g;
  // Sostituisci i caratteri non alfanumerici con una stringa vuota
  let text = testo.toLowerCase().replaceAll(" ", "-")
  return text.replace(regex, '').replaceAll("--", "");
}

export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}