import { NotificationContent } from "../components";
import { sendNotification } from "../hooks/useNotification";

export const setLoading = (loading) => window.dispatchEvent(new CustomEvent('change_loading', { detail: loading }));


export const errorInterceptor = (error, serviceName) => {
  if (error.response.data.detail === "Invalid token or expired token." || error.response.data.detail === "Invalid authentication scheme." || error.response.data.detail === "Invalid authorization code.") {
    window.location.assign("/");
    sendNotification(
      <NotificationContent service={serviceName} />,
      error.response.data.detail,
      "error",
      5
    );
  } else {
    sendNotification(
      <NotificationContent service={serviceName} />,
      error.response.data.detail,
      "error",
      5
    );
  }
};