import {
  AutoFixHighSharp,
  CircleTwoTone,
  CloudUpload,
  Delete,
  Download,
} from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect } from "react";
import { ProcessStateContext } from "../context";
import { useParams } from "react-router-dom";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ProjectFile = () => {
  const [pageSize, setPageSize] = React.useState(25);
  const { id } = useParams();
  const {
    state: { files_list },
    getFileList,
    addFile,
    deleteFileRecord,
    generateFileRewrite,
  } = useContext(ProcessStateContext);

  useEffect(() => {
    if (id) {
      getFileList(id);
    }
  }, []);



  const handleFileRead = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const binaryFileContent = await handleFileRead(file);
        const base64FileContent = arrayBufferToBase64(binaryFileContent);

        const jsonPayload = {
          project_id: id,
          file_name: file.name,
          file_stream_not_process: base64FileContent,
        };

        // Aggiungi il file alla lista dei file (puoi modificarlo secondo le tue necessità)
        addFile(id, jsonPayload);

        console.log("File uploaded:", jsonPayload);
      } catch (error) {
        console.error("Error reading file:", error);
      }
    }
  };

  const downloadFile = (fileName, base64Content) => {
    const link = document.createElement("a");
    const blob = base64ToBlob(base64Content, fileName);
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const base64ToBlob = (base64, fileName) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    let mimeType = "";
    if (fileName.endsWith(".pdf")) {
      mimeType = "application/pdf";
    } else if (fileName.endsWith(".txt")) {
      mimeType = "text/plain";
    } else {
      mimeType = "application/octet-stream";
    }
    return new Blob([byteArray], { type: mimeType });
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <ButtonGroup variant="contained">
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUpload />}
            color="secondary"
          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              accept=".txt"
              onChange={handleFileUpload}
            />
          </Button>
        </ButtonGroup>
      </Grid>

      <DataGrid
        autoHeight
        rows={files_list}
        columns={[
          {
            field: "state",
            headerName: "",
            renderCell: ({ row }) =>
              row.state !== "enabled" ? (
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  p={1}
                >
                  {" "}
                  <CircularProgress size={30}/>
                </Stack>
              ) : (
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"center"}
                  p={1.5}
                >
                  {" "}
                  <CircleTwoTone color="success" />{" "}
                </Stack>
              ),
          },
          {
            field: "",
            headerName: "Azioni",
            width: 150,
            renderCell: ({ row }) => (
              <Stack
                direction={"row"}
                spacing={2}
                justifyContent={"center"}
                p={1}
              >
                <IconButton
                  size="small"
                  onClick={() => deleteFileRecord(id, row)}
                >
                  <Delete color="error" />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => generateFileRewrite(id, row)}
                  disabled={row.state !== "enabled"}
                >
                  <AutoFixHighSharp color={row.state === "enabled" ? "success" :"inherit"} />
                </IconButton>
              </Stack>
            ),
          },
          { field: "file_name", flex: 1, headerName: "Nome File" },
          {
            field: "file_stream_not_process",
            flex: 1,
            headerName: "File di base",
            renderCell: ({ row }) =>
              row.file_stream_not_process !== null ? (
                <Button
                variant="contained"
                color="warning"
                startIcon={<Download></Download>}
                  onClick={() =>
                    downloadFile(row.file_name, row.file_stream_not_process)
                  }
                >
                  Scarica Master
                </Button>
              ) : null,
          },
          {
            field: "file_stream_process",
            flex: 1,
            headerName: "File Modificato",
            renderCell: ({ row }) =>
              row.file_stream_process !== null ? row.state === "enabled" ? (
                <Button
                  variant="outlined"
                  startIcon={<Download></Download>}
                  color="secondary"
                  onClick={() =>
                    downloadFile(
                      "rewrite_" + row.file_name,
                      row.file_stream_process
                    )
                  }
                >
                  Scarica
                </Button>
              ) : (<>Operazione in corso ...</>) : (
                <>Per generare il testo clicca sulla bacchetta magica</>
              ),
          },
        ]}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        disableSelectionOnClick
        disableRowSelectionOnClick={false}
      />
    </Grid>
  );
};

export default ProjectFile;
