import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useContext } from "react";
import { AuthContext, DomainContext } from "../context";
import { TypeCategories, TypeModeDomain, isWordpress } from "../utilities";
import {
  Add,
  AutoAwesome,
  DeleteOutlineTwoTone,
  VisibilityTwoTone,
} from "@mui/icons-material";
import { FormDialog } from "../components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useForm, { isRequired } from "../hooks/useForm";

const ListsDomain = () => {
  const {
    state: { lists_domain },
    getDomains,
    postDomain,
    deleteDomain,
    uploadPostOnWpSite,
    filterList,
    clearDomainList,
  } = useContext(DomainContext);

  const { updateDataContent } = useContext(AuthContext);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openGear, setOpenGear] = useState(false);
  const [textOpenGear, setTextOpenGear] = useState("");
  const [IsRewriteDomain, setIsRewriteDomain] = useState(false);
  const [validations, setValidation] = useState();
  const [value, setValue] = useState("");
  const [typeDomain, setTypeDomain] = useState("clone");
  const [pageSize, setPageSize] = React.useState(10);
  let dataContent = JSON.parse(localStorage.getItem("dataContent"));

  const initialValidations = [
    ({ url }) =>
      isRequired(url) || { url: "*Inserire il sito di destinazione" },
    ({ user }) => isRequired(user) || { user: "*Inserire Username Wp" },
    ({ password }) =>
      isRequired(password) || { password: "*Inserire password Wp" },
  ];

  useEffect(() => {
    getDomains();
    return () => {
      clearDomainList();
    };
  }, []);

  useEffect(() => {
    if (IsRewriteDomain) {
      setValidation([
        ({ url }) =>
          isRequired(url) || { url: "*Inserire il sito di destinazione" },
        ({ user_domain_rewrite }) =>
          isRequired(user_domain_rewrite) || {
            user_domain_rewrite: "*Inserire Username Wp",
          },
        ({ pass_domain_rewrite }) =>
          isRequired(pass_domain_rewrite) || {
            pass_domain_rewrite: "*Inserire password Wp",
          },
      ]);
    } else {
      setValidation(initialValidations);
    }
  }, [IsRewriteDomain]);

  const {
    values: FormWpInvio,
    changeHandler,
    touched,
    errors,
    setValues,
    isValid,
  } = useForm(
    {
      user: "",
      password: "",
      categoria: "",
      url: "",
      uuid_domain: "",
      user_domain_rewrite: "",
      pass_domain_rewrite: "",
      is_mine: "enabled",
    }
    /* validations */
  );

  const columns = [
    {
      field: "",
      headerName: "Azioni",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={2}>
            <Tooltip
              title={`Questa azione permette di visualizzare i post ${params.row.url_domain}`}
            >
              <IconButton
                onClick={() => {
                  navigate(`/lista-domini/${params.row._uuid}`);
                }}
                disabled={params.row.is_wordpress !== "is_wordpress"}
              >
                <VisibilityTwoTone
                  color={
                    params.row.is_wordpress !== "is_wordpress"
                      ? "disabled"
                      : "info"
                  }
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={`Questa azione ${
                params.row.type_domain === "clone"
                  ? "Carica i post da questo sito, al tuo sito Wordpress"
                  : params.row.type_domain === "update"
                  ? `Aggiorna i post sul sito ${params.row.url_domain}`
                  : "Creare i post con un file precompilato"
              }`}
            >
              <IconButton
                disabled={params.row.is_wordpress !== "is_wordpress"}
                onClick={() => {
                  setOpenGear(!openGear);
                  changeHandler("uuid_domain", params.row._uuid);
                  setIsRewriteDomain(
                    params.row.type_domain !== "update" ? false : true
                  );
                  setTextOpenGear(
                    params.row.type_domain === "clone"
                      ? "Carica i post da questo sito, al tuo sito Wordpress"
                      : params.row.type_domain === "update"
                      ? `Aggiorna i post sul sito ${params.row.url_domain}`
                      : "Creare i post con un file precompilato"
                  );
                  if (params.row.type_domain === "update") {
                    setValues({
                      ...FormWpInvio,
                      url: params.row.url_domain,
                      user_domain_rewrite: params.row.user_domain_rewrite,
                      pass_domain_rewrite: params.row.pass_domain_rewrite,
                    });
                  } else {
                    let dataCon = dataContent?.find(
                      (res) => res.id === params.row._uuid
                    );
                    setValues({
                      ...FormWpInvio,
                      user: dataCon ? dataCon.user : "",
                      password: dataCon ? dataCon.password : "",
                      categoria: "",
                      url: dataCon ? dataCon.url : "",
                      uuid_domain: params.row._uuid,
                      user_domain_rewrite: "",
                      pass_domain_rewrite: "",
                    });
                  }
                }}
              >
                <AutoAwesome
                  color={
                    params.row.is_wordpress !== "is_wordpress"
                      ? "disabled"
                      : "primary"
                  }
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={`Elimina il dominio ${params.row.url_domain} dalla lista`}
            >
              <IconButton onClick={() => deleteDomain(params.row.id)}>
                <DeleteOutlineTwoTone color="error" />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      field: "is_mine",
      headerName: "Categoria",
      flex: 1,
      editable: false,
      renderCell: (params) => TypeCategories(params.row.is_mine),
    },
    {
      field: "url_domain",
      headerName: "Dominio",
      flex: 1,
      editable: false,
    },
    {
      field: "type_domain",
      headerName: "Modalità Dominio",
      flex: 1,
      editable: false,
      renderCell: (params) => TypeModeDomain(params.row.type_domain),
    },
    {
      field: "is_wordpress",
      headerName: "Tipo di Sito",
      flex: 1,
      editable: false,
      renderCell: (params) => isWordpress(params.row.is_wordpress),
    },
  ];

  // ---------- form clona dominio -------------------------------------------------
  const FormDialogDomain = (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Indirizzo Dominio"
          type="address"
          fullWidth
          variant="standard"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={12} pt={2}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Scegli il tipo di dominio che desideri aggiungere
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={typeDomain}
            onChange={(e) => setTypeDomain(e.target.value)}
          >
            <FormControlLabel value="clone" control={<Radio />} label="Clona" />
            <FormControlLabel
              value="update"
              control={<Radio />}
              label="Aggiorna"
            />
            <FormControlLabel
              value="created"
              control={<Radio />}
              label="Crea"
              disabled
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {typeDomain === "update" ? (
        <>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="username"
              type="text"
              fullWidth
              variant="standard"
              value={FormWpInvio.user_domain_rewrite}
              helperText={
                touched.user_domain_rewrite && errors.user_domain_rewrite
              }
              error={Boolean(
                touched.user_domain_rewrite && errors.user_domain_rewrite
              )}
              onChange={(e) =>
                changeHandler("user_domain_rewrite", e.target.value)
              }
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TextField
              autoFocus
              margin="dense"
              id="pass"
              label="password"
              type="password"
              fullWidth
              variant="standard"
              value={FormWpInvio.pass_domain_rewrite}
              helperText={
                touched.pass_domain_rewrite && errors.pass_domain_rewrite
              }
              error={Boolean(
                touched.pass_domain_rewrite && errors.pass_domain_rewrite
              )}
              onChange={(e) =>
                changeHandler("pass_domain_rewrite", e.target.value)
              }
            />
          </Grid>
        </>
      ) : (
        <></>
      )}
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          select
          autoFocus
          margin="dense"
          id="select"
          label=""
          fullWidth
          variant="standard"
          value={FormWpInvio.is_mine}
          helperText={touched.is_mine && errors.is_mine}
          error={Boolean(touched.is_mine && errors.is_mine)}
          onChange={(e) => changeHandler("is_mine", e.target.value)}
        >
          <MenuItem value={"enabled"}>
            Proprietario
          </MenuItem>
          <MenuItem value={"disabled"}>
            Competitor
          </MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );

  const handleSend = () => {
    setOpen(false);
    let valueRep = value.replace("https://", "").replace("/", "");
    postDomain(
      "https://" + valueRep,
      typeDomain,
      FormWpInvio.user_domain_rewrite,
      FormWpInvio.pass_domain_rewrite,
      FormWpInvio.is_mine,
    );
    setValue("");
  };

  const ButtonDialogDomain = (
    <>
      <Button
        onClick={() => handleSend()}
        variant="contained"
        disabled={value === "" ? true : false}
      >
        Salva
      </Button>
    </>
  );
  // ---------------------------------------------------------------------------------------------------------------------------

  // ---------- form singolo dominio * il massivo del clone e del rewrite con varie condizioni ---------------------------------
  const FormDialogGearAction = (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <TextField
          autoFocus
          margin="dense"
          id="site_wp"
          label="Indirizzo sito web"
          type="site"
          disabled={IsRewriteDomain}
          fullWidth
          variant="standard"
          value={FormWpInvio.url}
          helperText={touched.url && errors.url}
          error={Boolean(touched.url && errors.url)}
          onChange={(e) => changeHandler("url", e.target.value)}
        />
      </Grid>
      {!IsRewriteDomain ? (
        <>
          <Grid item xs={12} md={12} lg={12}>
            <TextField
              autoFocus
              margin="dense"
              id="username"
              label="username wp"
              type="text"
              fullWidth
              variant="standard"
              value={FormWpInvio.user}
              helperText={touched.user && errors.user}
              error={Boolean(touched.user && errors.user)}
              onChange={(e) => changeHandler("user", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TextField
              autoFocus
              margin="dense"
              id="pass"
              label="Password delle applicazioni"
              type="password"
              fullWidth
              variant="standard"
              value={FormWpInvio.password}
              helperText={touched.password && errors.password}
              error={Boolean(touched.password && errors.password)}
              onChange={(e) => changeHandler("password", e.target.value)}
            />
          </Grid>
        </>
      ) : (
        <>
          <>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus
                margin="dense"
                id="username"
                label="username"
                type="text"
                disabled
                fullWidth
                variant="standard"
                value={FormWpInvio.user_domain_rewrite}
                helperText={
                  touched.user_domain_rewrite && errors.user_domain_rewrite
                }
                error={Boolean(
                  touched.user_domain_rewrite && errors.user_domain_rewrite
                )}
                onChange={(e) =>
                  changeHandler("user_domain_rewrite", e.target.value)
                }
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <TextField
                autoFocus
                margin="dense"
                id="pass"
                disabled
                label="password"
                type="password"
                fullWidth
                variant="standard"
                value={FormWpInvio.pass_domain_rewrite}
                helperText={
                  touched.pass_domain_rewrite && errors.pass_domain_rewrite
                }
                error={Boolean(
                  touched.pass_domain_rewrite && errors.pass_domain_rewrite
                )}
                onChange={(e) =>
                  changeHandler("pass_domain_rewrite", e.target.value)
                }
              />
            </Grid>
          </>
        </>
      )}
    </Grid>
  );

  const ButtonDialogGear = (
    <>
      <Button
        onClick={() => {
          if (IsRewriteDomain) {
            /* console.log("IsRewriteDomain devo mettere il metodo"); */
          } else {
            uploadPostOnWpSite(
              FormWpInvio,
              FormWpInvio.uuid_domain,
              setOpenGear
            );
            updateDataContent(
              {
                id: FormWpInvio.uuid_domain,
                user: FormWpInvio.user,
                password: FormWpInvio.password,
                url: FormWpInvio.url,
              },
              setOpenGear
            );
          }
        }}
        variant="contained"
      >
        {IsRewriteDomain ? "Aggiorna Post" : "Invia Post"}
      </Button>
    </>
  );
  // ----------------------------------------------------------------------------------------------------------------------------------------

  return (
    <Box sx={{ width: "100%" }}>
      {/*  sezione dei form */}
      <FormDialog
        FormContent={FormDialogDomain}
        ButtonDialog={ButtonDialogDomain}
        formFiled={true}
        open={open}
        setOpen={setOpen}
        sendMethod={postDomain}
        text={
          "Con questo form puoi aggiungere un nuovo dominio, potrai conoscere il tipo di sito, se si tratta di un wordpress oppure no."
        }
        title={"Salva Dominio"}
      ></FormDialog>
      <FormDialog
        FormContent={FormDialogGearAction}
        ButtonDialog={ButtonDialogGear}
        formFiled={true}
        open={openGear}
        setOpen={setOpenGear}
        text={textOpenGear}
        /* title={"Invio Massivo"} */
      ></FormDialog>
      {/*  sezione dei form */}

      <ButtonGroup variant="contained">
        <Button
          startIcon={<Add />}
          onClick={() => setOpen(!open)}
          color="secondary"
        >
          Aggiungi Dominio
        </Button>
      </ButtonGroup>
      <Stack direction={"row"} spacing={2} mt={2} mb={2}>
        <TextField
          margin="normal"
          fullWidth
          label="Ricerca Dominio"
          autoComplete="off"
          autoFocus
          onChange={(e) => filterList(e.target.value, lists_domain)}
        />
      </Stack>

      <DataGrid
        autoHeight
        rows={lists_domain}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        disableSelectionOnClick
        disableRowSelectionOnClick={false}
      />
    </Box>
  );
};

export default ListsDomain;
