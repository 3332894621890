import createDataContext from "./createContext";
import { TokenApi } from "../api";
import { NotificationContent } from "../components";
import { sendNotification } from "../hooks/useNotification";

const USER_LIST = "user_list";
const USER_DETAIL = "user_detail";


const INITIAL_STATE = {
  usersList: [],
  user:{}
};


const usersAdminReducer = (state, action) => {
  switch (action.type) {
    case USER_LIST: {
      const { usersList  } = action.payload;
      return { ...state, usersList };
    }
    case USER_DETAIL: {
      const { user  } = action.payload;
      return { ...state, user };
    }
    default: {
      return state;
    }
  }
};


const getUserProfiles = (dispatch) => async () => {
  const response = await TokenApi.get(`/v1/users`);
  if(response.data){
    dispatch({ type: USER_LIST, payload:{ usersList : response.data.data }});
  }
};

const getUser= (dispatch) => async (_uuid) => {
  const response = await TokenApi.get(`/v1/users/${_uuid}`);
  if(response.data){
    dispatch({ type: USER_DETAIL, payload:{ user : response.data.data }});
  }
};

const getClearUser = (dispatch) => async ()=>{
  dispatch({ type: USER_DETAIL, payload:{ user : {} }});
}

const putUser = (dispatch) => async (data, navigate)=>{
  const response = await TokenApi.put(`/v1/users/`, data);
  if (response){
    navigate(-1)
    sendNotification(
      <NotificationContent service={"Utente modificato"} />,
      "Utente modificato con successo!",
      "info",
      5
    );
  }

}
const deleteUserProfile = (dispatch) => async (id_user)=>{
  const response = await TokenApi.delete(`/v1/users/${id_user}`);
  if (response){
    sendNotification(
      <NotificationContent service={"Utente modificato"} />,
      "Utente eliminato con successo!",
      "info",
      5
    );
  }

}
const postUser = (dispatch) => async (data, navigate)=>{
  const response = await TokenApi.post(`/v1/users/new`, data);
  if (response){
    navigate(-1)
    sendNotification(
      <NotificationContent service={"Utente Creato"} />,
      "Utente Creato con successo!",
      "success",
      5
    );
  }

}



export const { Provider, Context } = createDataContext(
    usersAdminReducer,
  {
    getUserProfiles,
    getUser,
    getClearUser,
    putUser,
    postUser,
    deleteUserProfile
  }, // actions
  INITIAL_STATE // initial state
);
