import createDataContext from "./createContext";
import { TokenApi } from "../api";
import { sendNotification } from "../hooks/useNotification";
import { NotificationContent } from "../components";

const LISTS_DOMAINS = "lists_domains";
const DOMAIN = "domain";
const URL_DOMAIN = "url_domain";
const GET_POST = "get_post";
const SET_TITLE = "set_title";
const TYPE_DOMAIN = "type_domain";
const LIST_CATEGORIES = "list_categories";
const LIST_DOMAIN_KEYWORDS = "list_domain_keywords";
const LIST_DOMAIN_KEYWORDS_SELECT = "list_domain_keywords_select";
const LIST_DOMAIN_KEYWORDS_TOP = "list_domain_keywords_top";


const INITIAL_STATE = {
  lists_domain: [],
  domain_post: [],
  domain_info: [],
  post: [],
  titleDrawer: "",
  type_domain: "",
  categories: [],
  listsDomainKeywords:[],
  listsDomainKeywordsTwo:[],
  listsDomainKeywordsTop:[],
  uniqueDomains:[],
};

const domainReducer = (state, action) => {
  switch (action.type) {
    case LISTS_DOMAINS: {
      const { lists_domain } = action.payload;
      return { ...state, lists_domain };
    }
    case DOMAIN: {
      const { domain_post, domain_info } = action.payload;
      return { ...state, domain_post, domain_info };
    }
    case URL_DOMAIN: {
      const { url } = action.payload;
      return { ...state, url };
    }
    case GET_POST: {
      const { post } = action.payload;
      return { ...state, post };
    }
    case SET_TITLE: {
      const { titleDrawer } = action.payload;
      return { ...state, titleDrawer };
    }
    case TYPE_DOMAIN: {
      const { type_domain } = action.payload;
      return { ...state, type_domain };
    }
    case LIST_CATEGORIES: {
      const { categories } = action.payload;
      return { ...state, categories };
    }
    case LIST_DOMAIN_KEYWORDS: {
      const { listsDomainKeywords, listsDomainKeywordsTwo } = action.payload;
      return { ...state, listsDomainKeywords, listsDomainKeywordsTwo };
    }
    case LIST_DOMAIN_KEYWORDS_TOP: {
      const { listsDomainKeywordsTop} = action.payload;
      return { ...state, listsDomainKeywordsTop};
    }
    case LIST_DOMAIN_KEYWORDS_SELECT: {
      const { listsDomainKeywords } = action.payload;
      const uniqueDomains = Array.from(
        new Set(listsDomainKeywords.map((post) => post.domain))
      );
      return { ...state, uniqueDomains };
    }
    default: {
      return state;
    }
  }
};

const getDomains = (dispatch) => async () => {
  const response = await TokenApi.get(`/v1/domains`);
  if (response.data) {
    dispatch({
      type: LISTS_DOMAINS,
      payload: { lists_domain: response.data.data },
    });
    if (response.data.data.length === 0) {
      sendNotification(
        <NotificationContent service={"Lista vuota"} />,
        "Non ci sono Domini salvati!",
        "info",
        5
      );
    }
  }
};

const clearDomainList = (dispatch)=> async ()=>{
  dispatch({
    type: DOMAIN,
    payload: {
      domain_post: [],
      domain_info: [],
    },
  });
}

const getDomain = (dispatch) => async (uuid) => {
  const response = await TokenApi.get(`/v1/domain/${uuid}`);
  if (response.data) {
    dispatch({
      type: DOMAIN,
      payload: {
        domain_post: response.data.data.domain_post,
        domain_info: response.data.data.domain_info,
      },
    });
  }
};

const getTypeDomain = (dispatch) => async (id) => {
  const response = await TokenApi.get(`/v1/domain/type/${id}`);
  if (response.data) {
    dispatch({
      type: TYPE_DOMAIN,
      payload: {
        type_domain: response.data.data.type_domain[0]?.type_domain,
      },
    });
  }
};

const getDomainFromCheck = (dispatch) => async (uuid, checkBox, setChekbox) => {
  const response = await TokenApi.get(`/v1/domain/${uuid}`);
  if (response.data) {
    dispatch({
      type: DOMAIN,
      payload: {
        domain_post: response.data.data.domain_post,
        domain_info: response.data.data.domain_info,
      },
    });
  }
};

const postDomain =
  (dispatch) =>
  async (body, type, user_domain_rewrite, pass_domain_rewrite, is_mine) => {
    const response = await TokenApi.post(`/v1/domain`, {
      id: -1,
      url_domain: body,
      type_domain: type,
      user_domain_rewrite: user_domain_rewrite,
      pass_domain_rewrite: pass_domain_rewrite,
      is_mine:is_mine
    });
    if (response.data) {
      getDomains(dispatch)();
    }
  };

const deleteDomain = (dispatch) => async (id) => {
  const response = await TokenApi.delete(`/v1/domain/${id}`);
  if (response.status === 200) {
    getDomains(dispatch)();
  }
};

const getPostsFromDomain =
  (dispatch) => async (id, domain, uuid, setOpenBk, modeDowload) => {
    setOpenBk(true);
    let data = {};
    if (modeDowload.posts){
      data = {
        id: id.toString(),
        url_domain: `https://${domain}/wp-json/wp/v2/posts`,
      };
      const response = await TokenApi.post(`/v1/domain/extract/post`, data);
      if (response.data) {
        getDomain(dispatch)(uuid);
        setOpenBk(false);
      }else{
        setOpenBk(false);
      }

    }else if (modeDowload.pages){
      data = {
        id: id.toString(),
        url_domain: `https://${domain}/wp-json/wp/v2/pages`,
      };
      const response = await TokenApi.post(`/v1/domain/extract/post`, data);
      if (response.data) {
        getDomain(dispatch)(uuid);
        setOpenBk(false);
      }else{
        setOpenBk(false);
      }
    }
  };
const selectDomain = (dispatch) => async (url) => {
  dispatch({ type: URL_DOMAIN, payload: { url: url } });
};

const postDomainPost = (dispatch) => async (uuid_domain, data, navigate) => {
  const response = await TokenApi.post(
    `/v1/domain/${uuid_domain}/newpost`,
    data
  );
  if (response.status === 200) {
    sendNotification(
      <NotificationContent service={"Inserimento riuscito"} />,
      "il post " + response.data.data[0].title_post + " è stato inserito!",
      "success",
      5
    );
    setTimeout(() => {
      navigate(-1);
      setTitle("Elenco post");
    }, 5000);
    //window.location.reload()
  }
};

const putDomainPost =
  (dispatch) => async (uuid_domain, id_post, data, navigate) => {
    const response = await TokenApi.put(
      `/v1/domain/${uuid_domain}/post/${id_post}`,
      data
    );
    if (response.status === 200) {
      sendNotification(
        <NotificationContent service={"Modifica riuscita"} />,
        "il post e' stato modificato!",
        "success",
        5
      );
      setTimeout(() => {
        navigate(-1);
        setTitle("Elenco post");
      }, 5000);
      //window.location.reload()
    }
  };

const pulisciPost = (dispatch) => async (_uuid) => {
  dispatch({ type: GET_POST, payload: { post: [] } });
};

const setTitle = (dispatch) => async (title) => {
  dispatch({ type: SET_TITLE, payload: { titleDrawer: title } });
};
const getDomainPost = (dispatch) => async (id_domain, id_post) => {
  const response = await TokenApi.get(
    `/v1/domain/${id_domain}/post/${id_post}`
  );
  if (response.data) {
    dispatch({ type: GET_POST, payload: { post: response.data.data.post } });
  }
};

const cleanPostTag =
  (dispatch) =>
  async (data, isLists, id_domain = null, id_post = null) => {
    const response = await TokenApi.put(`/v1/domain/clean/tag`, data);
    if (isLists) {
      getDomain(dispatch)(data.uuid_domain);
    }
    if (response.data) {
      sendNotification(
        <NotificationContent service={"Ricetta riuscita"} />,
        "il post è stato pulito dai Tag!",
        "success",
        5
      );
      if (isLists) {
        getDomain(dispatch)(data.uuid_domain);
      } else {
        getDomain(dispatch)(data.uuid_domain);
        getDomainPost(dispatch)(id_domain, id_post);
      }
    }
  };

const cleanBrandTarget =
  (dispatch) =>
  async (data, isLists, id_domain = null, id_post = null) => {
    const response = await TokenApi.put(`/v1/domain/clean/brand`, data);
    if (isLists) {
      getDomain(dispatch)(data.uuid_domain);
    }
    if (response.data) {
      sendNotification(
        <NotificationContent service={"Ricetta riuscita"} />,
        "Dal post e' stato cancellato ogni rifirimento a" + data.sito,
        "success",
        5
      );
      if (isLists) {
        getDomain(dispatch)(data.uuid_domain);
      } else {
        getDomainPost(dispatch)(id_domain, id_post);
      }
    }
  };

const beautifyTextOpenAi =
  (dispatch) =>
  async (data, isLists, id_domain = null, id_post = null) => {
    const words = data.post_content.split(/\s+/);
    const wordCount = words.length*2;
    if (wordCount > 7500) {
      sendNotification(
        <NotificationContent service={"Errore!"} />,
        "Il post contiente troppi token (" +
          wordCount +
          ") il massimo accettato per il prompt é 8000",
        "error",
        10
      );
    }else{
      const response = await TokenApi.put(`/v1/openai/best/text`, data);
    if (isLists) {
      getDomain(dispatch)(data.uuid_domain);
    }
    if (response.data) {
      sendNotification(
        <NotificationContent service={"Richista inviata..."} />,
        "Attendere qualche minuto",
        "warning",
        5
      );
    }
    }
    
    
  };

  const expandTextOpenAi =
  (dispatch) =>
  async (data, isLists, id_domain = null, id_post = null) => {
    const words = data.post_content.split(/\s+/);
    const wordCount = words.length*2;
    if (wordCount > 7500) {
      sendNotification(
        <NotificationContent service={"Errore!"} />,
        "Il post contiente troppi token (" +
          wordCount +
          ") il massimo accettato per il prompt é 8000",
        "error",
        10
      );
    }else{
      const response = await TokenApi.put(`/v1/openai/best/text/expand`, data);
    if (isLists) {
      getDomain(dispatch)(data.uuid_domain);
    }
    if (response.data) {
      sendNotification(
        <NotificationContent service={"Richista inviata..."} />,
        "Attendere qualche minuto",
        "warning",
        5
      );
    }
    }    
  };

const cleanBrandTargetMassive =
  (dispatch) => async (data, uuid_domain, sito) => {
    const response = await TokenApi.put(
      `/v1/domain/clean/brand/massive/${uuid_domain}/${sito}`,
      data
    );
    if (response.data) {
      sendNotification(
        <NotificationContent service={"Richiesta Massiva"} />,
        "ok",
        "success",
        5
      );
    }
  };

const cleanTagMassive = (dispatch) => async (data, uuid_domain, sito) => {
  const response = await TokenApi.put(
    `/v1/domain/clean/tag/massive/${uuid_domain}/${sito}`,
    data
  );
  if (response.data) {
    sendNotification(
      <NotificationContent service={"Richiesta Massiva"} />,
      "ok",
      "success",
      5
    );
  }
};

const cleanTextMassive = (dispatch) => async (data, uuid_domain, sito) => {
  const response = await TokenApi.put(
    `/v1/domain/best/text/massive/${uuid_domain}/${sito}`,
    data
  );
  if (response.data) {
    sendNotification(
      <NotificationContent service={"Richiesta Massiva"} />,
      "ok",
      "success",
      5
    );
  }
};

const UpdatePostWpMassive = (dispatch) => async (data, id_domain) => {
  const response = await TokenApi.post(
    `v1/domain/wp/${id_domain}/rewrite/massive`,
    data
  );
  if (response.data) {
    sendNotification(
      <NotificationContent service={"Richiesta Massiva"} />,
      "ok",
      "success",
      5
    );
  }
};

const uploadPostOnWpSite =
  (dispatch) => async (data, uuid_domain, setOpenGear) => {
    const response = await TokenApi.post(
      `/v1/domain/wp/actions/upload/${uuid_domain}`,
      data
    );
    if (response.data) {
      sendNotification(
        <NotificationContent service={"Richiesta Inviata"} />,
        "ok",
        "success",
        5
      );
      setOpenGear(false);
    }
  };

const uploadPostOnWpSiteUpdated =
  (dispatch) => async (id_domain, data, uuid_domain) => {
    const response = await TokenApi.post(
      `/v1/domain/wp/${id_domain}/rewrite/`,
      data
    );
    if (response.data) {
      sendNotification(
        <NotificationContent service={"Ok Aggiornato!"} />,
        "ok",
        "success",
        5
      );
      getDomain(dispatch)(uuid_domain);
    }
  };

const uploadPostOnWpSiteUpdatedAllPlat =
  (dispatch) => async (id_domain, data, uuid_domain) => {
    const response = await TokenApi.post(
      `/v1/domain/wp/${id_domain}/rewrite/`,
      data
    );
    if (response.data) {
      sendNotification(
        <NotificationContent service={"Ok Aggiornato!"} />,
        "ok",
        "success",
        5
      );
      getDomain(dispatch)(uuid_domain);
    }
  };

const putDomainPostAll =
  (dispatch) =>
  async (uuid_domain, id_post, data, navigate, lists_domain_id, dataForm) => {
    const response = await TokenApi.put(
      `/v1/domain/${uuid_domain}/post/${id_post}`,
      data
    );
    if (response.status === 200) {
      sendNotification(
        <NotificationContent service={"Modifica riuscita"} />,
        "il post e' stato modificato!",
        "success",
        5
      );
      setTimeout(() => {
        navigate(-1);
        setTitle("Elenco post");
        uploadPostOnWpSiteUpdatedAllPlat(dispatch)(
          lists_domain_id,
          dataForm,
          uuid_domain
        );
      }, 5000);
    }
  };

const checkPostFree =
  (dispatch) =>
  async (
    uuid_domain,
    id_post,
    selectedIDs,
    setSelectedRows,
    domain_post,
    selectedRowsModel,
    setSelectedRowsModel
  ) => {
    const selectedRows = domain_post.filter((row) => selectedIDs.has(row.id));
    if (id_post) {
      const response = await TokenApi.get(
        `/v1/domain/${uuid_domain}/post/${id_post}/check`
      );
      if (response.data) {
        if (response.data.data.state_post) {
          /* getDomainFromCheck(dispatch)(uuid_domain); */
          setSelectedRows(selectedRows);
        } else {
          sendNotification(
            <NotificationContent service={"Attenzione!"} />,
            "Post non selezionato, in lavorazione da parte di un altro utente",
            "error",
            10
          );
          getDomainFromCheck(dispatch)(uuid_domain);
          setSelectedRows(selectedRows.filter((row) => row.id !== id_post));
          setSelectedRowsModel(
            selectedRowsModel.filter((row) => row !== id_post)
          );
        }
      } else {
        /* console.log("errore"); */
      }
    }
  };

const checkPostFreeTwo =
  (dispatch) => async (uuid_domain, id_post, method, bodyMethod, isTrue) => {
    if (id_post) {
      const response = await TokenApi.get(
        `/v1/domain/${uuid_domain}/post/${id_post}/check`
      );
      if (response.data) {
        if (response.data.data.state_post) {
          method(bodyMethod, isTrue);
        } else {
          sendNotification(
            <NotificationContent service={"Attenzione!"} />,
            "Post non selezionato, in lavorazione da parte di un altro utente",
            "error",
            10
          );
          getDomain(dispatch)(uuid_domain);
        }
      } else {
        return false;
      }
    }
  };
const getListDomainKey =
  (dispatch) => async () => {
    const response = await TokenApi.get(
      `/v1/keywords`
    );
    if(response.data){
      dispatch({
        type: LIST_DOMAIN_KEYWORDS,
        payload: {
          listsDomainKeywords: response.data.data.storage,
        },
      });
    }
  };

const getListDomainForSelect =
  (dispatch) => async () => {
    const response = await TokenApi.get(
      `/v1/keywords`
    );
    if(response.data){
      dispatch({
        type: LIST_DOMAIN_KEYWORDS_SELECT,
        payload: {
          listsDomainKeywords: response.data.data.storage,
        },
      });
    }
  };


  const getListKeyWords =
  (dispatch) => async () => {
    const response = await TokenApi.get(
      `/v1/keywords/top`
    );
    if(response.data){
      dispatch({
        type: LIST_DOMAIN_KEYWORDS_TOP,
        payload: {
          listsDomainKeywordsTop: response.data.data.storage,
        },
      });
    }
  };

const uploadPostOnWpSiteMultiSelect =
  (dispatch) => async (cred, uuid_domain, data) => {
    let payload = [{ cred: cred, content: data }];
    const response = await TokenApi.post(
      `/v1/domain/wp/actions/multiselect/upload`,
      payload
    );
    if (response) {
      sendNotification(
        <NotificationContent service={"Post Inviato!"} />,
        "Il Post selezionato e' stato inviato correttamente",
        "success",
        10
      );
    }
  };

const takeWpCategoriesByCred = (dispatch) => async (payload) => {
  const response = await TokenApi.post(
    `/v1/domain/wp/categories/list`,
    payload
  );
  if (response) {
    dispatch({
      type: LIST_CATEGORIES,
      payload: { categories: response.data.data },
    });
  }
};

const AddNewCategoriesOnWpByCred =
  (dispatch) => async (cred, payload, setOpenCategoriesNew) => {
    const response = await TokenApi.post(`/v1/domain/wp/categories/new`, {
      cred: cred,
      payload: payload,
    });
    if (response) {
      setOpenCategoriesNew(false);
    }
  };
  
const replaceText =
  (dispatch) => async (data) => {
    const response = await TokenApi.post(`/v1/domain/replace/sentence`, data);
  };

  const replaceTextMassive =
  (dispatch) => async (data, textToRemove, domain_uuid, closeDialog) => {
    let payload = []
    data.forEach((element) =>{
      let newData = {
        'uuid_post': element._uuid,
        'remove_text': textToRemove
      }
      payload = [...payload, newData]
    })

    const response = await TokenApi.put(`/v1/domain/replace/sentence/massive/${domain_uuid}`, payload);
    if(response){
      sendNotification(
        <NotificationContent service={"Richiesta Inviata con successo"} />,
         "i testi sono stati modificati",
         "success",
         10
       );
       closeDialog(false)
       getDomain(dispatch)(domain_uuid);
    }
  };

const LokiVariantGenerate =
  (dispatch) =>
  async (data, isLists, id_domain = null, id_post = null) => {
    const words = data.post_content.split(/\s+/);
    const wordCount = words.length*2;
    if (wordCount > 7500) {
      sendNotification(
        <NotificationContent service={"Errore!"} />,
        "Il post contiente troppi token (" +
          wordCount +
          ") il massimo accettato per il prompt é 8000",
        "error",
        10
      );
    } else {
      const response = await TokenApi.post(
      `/v1/domain/wp/variant`,
      data
    );
    if (response){
      sendNotification(
       <NotificationContent service={"Richiesta Inviata con successo"} />,
        "a breve potrai vedere come il tuo testo sia variato attendi...",
        "success",
        10
      );
    }
    }
  };

  const filterList =
  (dispatch) => async (search, list) => {
   if(search !== ""){
     const filteredList = list.filter(res => res.url_domain.includes(search));
     dispatch({
       type: LISTS_DOMAINS,
       payload: { lists_domain: filteredList },
     });
   }else{
    getDomains(dispatch)();
   }
  };


  const deletePostByUuid = (dispatch) => async (uuid_post, uuid_domain) => {
    const response = await TokenApi.delete(`/v1/domain/${uuid_post}/post-action`);
    if (response) {
      sendNotification(
        <NotificationContent service={"Post eliminato"} />,
         "il post e' stato eliminato!",
         "success",
         10
       );
      getDomain(dispatch)(uuid_domain);
    }
  };

 

  const filterListKeywords =
  (dispatch) => async (search, list) => {
    const response = await TokenApi.get(
      `/v1/keywords`
    );
   if(search !== ""){
    const searchLower = search.toLowerCase();
    const filteredList = response.data.data.storage.filter(res =>
        (res.keywords && res.keywords.some(keyword => keyword.toLowerCase().includes(searchLower))) ||
        (res.link_post && res.link_post.toLowerCase().includes(searchLower)) 
    );
     dispatch({
      type: LIST_DOMAIN_KEYWORDS,
      payload: {
        listsDomainKeywords: filteredList,
      },
    });
   }else{
    getListDomainKey(dispatch)();
   }
  };

  const filterListKeywordsDomainOnly =
  (dispatch) => async (search, list) => {
    const response = await TokenApi.get(
      `/v1/keywords`
    );
   if(search !== ""){
    const searchLower = search.toLowerCase();
    const filteredList = response.data.data.storage.filter(res =>
        (res && res.domain.includes(searchLower))
    );
     dispatch({
      type: LIST_DOMAIN_KEYWORDS,
      payload: {
        listsDomainKeywords: filteredList,
      },
    });
   }else{
    getListDomainKey(dispatch)();
   }
  };

  const resetDomainKeywords =
  (dispatch) => async (search, list) => {
    dispatch({
      type: LIST_DOMAIN_KEYWORDS,
      payload: {
        listsDomainKeywords: [],
      },})
  }

  const generateMetaDescription = (dispatch) => async (titleH1, setValues, postForm ) => {
    sendNotification(
      <NotificationContent service={"Attendi un secondo!"} />,
       "stiamo generando la tua meta-description",
       "warning",
       5
     );
    const response = await TokenApi.post(`/v1/new/enhance/metadescription`, {text:titleH1});
    if (response) {
      sendNotification(
        <NotificationContent service={"Meta Description Generato da ChatGPT!"} />,
         "la richiesta e' stata eseguita con successo",
         "success",
         10
       );
       console.log('response: ', response);
       setValues({...postForm, meta_description:response.data.data})
    }
  };
  const generateMetaTitle = (dispatch) => async (titleH1, setValues, postForm ) => {
    sendNotification(
      <NotificationContent service={"Attendi un secondo!"} />,
       "stiamo generando la tua meta-title",
       "warning",
       5
     );
    const response = await TokenApi.post(`/v1/new/enhance/metatitle`, {text:titleH1});
    if (response) {
      sendNotification(
        <NotificationContent service={"Meta Title Generato da ChatGPT!"} />,
         "la richiesta e' stata eseguita con successo",
         "success",
         10
       );
       setValues({...postForm, tag_title:response.data.data})
    }
  };

  const generateTenTitle = (dispatch) => async (title, setValues, postForm, setOpenModal, setEnancheTitle) => {
    sendNotification(
      <NotificationContent service={"Attendi un secondo!"} />,
       "stiamo generando 10 opzioni valide di Titoli...",
       "warning",
       5
     );
    const response = await TokenApi.post(`/v1/new/enhance/title`, {text:title});
    if (response) {
      setEnancheTitle(response.data.data)
      sendNotification(
        <NotificationContent service={"Lista Titoli Generata da ChatGPT!"} />,
         "la richiesta e' stata eseguita con successo",
         "success",
         10
       );
       setOpenModal(true)
    }
  };

export const { Provider, Context } = createDataContext(
  domainReducer,
  {
    getDomains,
    getDomain,
    postDomain,
    getPostsFromDomain,
    selectDomain,
    postDomainPost,
    putDomainPost,
    getDomainPost,
    getTypeDomain,
    cleanTextMassive,
    pulisciPost,
    cleanPostTag,
    cleanBrandTarget,
    beautifyTextOpenAi,
    cleanBrandTargetMassive,
    cleanTagMassive,
    setTitle,
    uploadPostOnWpSite,
    checkPostFree,
    checkPostFreeTwo,
    deleteDomain,
    uploadPostOnWpSiteUpdated,
    putDomainPostAll,
    uploadPostOnWpSiteUpdatedAllPlat,
    UpdatePostWpMassive,
    uploadPostOnWpSiteMultiSelect,
    takeWpCategoriesByCred,
    AddNewCategoriesOnWpByCred,
    LokiVariantGenerate,
    filterList,
    replaceText,
    replaceTextMassive,
    deletePostByUuid,
    clearDomainList,
    getListDomainKey,
    getListKeyWords,
    filterListKeywords,
    filterListKeywordsDomainOnly,
    getListDomainForSelect,
    resetDomainKeywords,
    generateMetaDescription,
    generateTenTitle,
    generateMetaTitle,
    expandTextOpenAi
  }, // actions
  INITIAL_STATE // initial state
);
