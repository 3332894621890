import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect } from "react";
import { DomainContext } from "../context";
import { IconButton, MenuItem, Stack, TextField, Tooltip } from "@mui/material";
import { blue, red } from "@mui/material/colors";
import { Info } from "@mui/icons-material";
import { FullScreenDialog } from "../components";

const TopKeyWords = () => {
  const {
    state: { listsDomainKeywordsTop },
    getListKeyWords,
    getListDomainForSelect,
    resetDomainKeywords,
  } = useContext(DomainContext);

  const [pageSize, setPageSize] = React.useState(10);

  const columns = [
    {
      field: "key_word",
      headerName: "Key-words",
      flex: 2,
      editable: false,
    },
    {
      field: "usage_count",
      headerName: "Contatore",
      flex: 1,
      editable: false,
    },
    {
      field: "",
      headerName: "info",
      editable: false,
      renderCell: ({ row }) => (
        <FullScreenDialog icon={<Info color="info" />} data={row} />
      ),
    },
  ];

  useEffect(() => {
    getListKeyWords();
    getListDomainForSelect();
    return () => {
      resetDomainKeywords();
    };
  }, []);

  return (
    <>
      <DataGrid
        sx={{
          "& .super-app.positive": {
            backgroundColor: red[100],
            borderColor: red[400],
          },
          "& .super-app.negative": {
            backgroundColor: blue[100],
            borderColor: blue[400],
          },
        }}
        autoHeight
        rows={listsDomainKeywordsTop}
        columns={columns}
        pageSize={pageSize}
        getRowId={(params) => params.id_post}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[10, 25, 50, 100]}
        disableSelectionOnClick
        disableRowSelectionOnClick={false}
      />
    </>
  );
};

export default TopKeyWords;
