import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ProcessStateContext } from "../context";
import { useContext } from "react";
import { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Chip, CircularProgress, Stack } from "@mui/material";
import { getRandomIntInclusive } from "../utilities";
import { CancelTwoTone } from "@mui/icons-material";

export default function Process() {
  const {
    state: { processList },
    getAllProcess,
    freeProcess
  } = useContext(ProcessStateContext);

  const [progress, setProgress] = React.useState(10);

  useEffect(() => {
    getAllProcess();
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 85 ? 74 : prevProgress + getRandomIntInclusive(2, 9)
      );
      getAllProcess();
    }, 7500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const columns = [
    {
      field: "username",
      headerName: "Utente",
    },
    {
      field: "title_post",
      headerName: "title_post",
      flex: 1,
    },
    {
      field: "is_download_db",
      headerName: "state",
      flex: 1,
      renderCell: ({ row }) => {
        if (row.is_download_db === "not_started" && row.post_state === "disabled") {
          return (
            <Chip
              label="Bloccato"
              onClick={() => {}}
              onDelete={() => {}}
              color="secondary"
              size="small" 
              deleteIcon={<CancelTwoTone />}
            />
          );
        } else if (row.is_download_db === "start") {
          return (
            <Stack spacing={2} direction={"row"}>
              <CircularProgressWithLabel value={progress} />
              <Box sx={{paddingTop:1}}>
              <Chip label="In esecuzione" color="info" size="small" />
              </Box>
            </Stack>
          );
        }else if (row.is_download_db === "in_progress" && row.post_state === "disabled"){
          return  <Chip
          label="Bloccato"
          onClick={() => {}}
          onDelete={() => {}}
          color="secondary"
          size="small" 
          deleteIcon={<CancelTwoTone />}
        />
        }else {
          return <CircularProgressWithLabel value={100} />;
        }
      },
    },
    {
      field: "",
      headerName: "Azioni",
      width: 150,
      renderCell: ({row}) =>{
        if (row.is_download_db === "not_started" && row.post_state === "disabled"){
          return  <Button onClick={()=> freeProcess(row.id, row.post_id)} variant="contained" color="warning">Sblocca</Button>
        }else if (row.is_download_db === "in_progress" && row.post_state === "disabled"){
          return  <Button onClick={()=> freeProcess(row.id, row.post_id)} variant="contained" color="warning" >Sblocca</Button>
        }else if (row.is_download_db === "start" && row.post_state === "disabled"){
          return  <Button onClick={()=> freeProcess(row.id, row.post_id)} variant="contained" color="error" >Blocca</Button>
        }
     }
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        rows={processList || []}
        columns={columns}
        autoHeight
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
  );
}
