import { DataArray, LoginTwoTone, Person2 } from '@mui/icons-material'
import { Button, Card } from '@mui/material'
import React from 'react'

const CardDivideComponent = ({ children, title }) => {
  return (
    <Card variant="outlined" sx={{width:"100%", marginBottom:2}}> 
    <Button variant="text" startIcon={<LoginTwoTone/>} color='info'>{title}</Button>
        {children}
    </Card>
  )
}

export default CardDivideComponent