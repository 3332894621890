import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { menuVoiceAdmin } from "../utilities";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AccountBoxTwoTone,
  AccountCircle,
  AccountCircleTwoTone,
  AllInclusive,
  BarChartTwoTone,
  CloudCircleTwoTone,
  ExpandLess,
  ExpandMore,
  KeyTwoTone,
  LensTwoTone,
  LogoutTwoTone,
  OpenInBrowserSharp,
  SaveAs,
  Settings,
  SevereCold,
  StorageTwoTone,
  StoreSharp,
  SupervisorAccountTwoTone,
} from "@mui/icons-material";
import { Chip, Collapse, Stack } from "@mui/material";
import { AuthContext, DomainContext } from "../context";
import { useContext } from "react";
import jwt_decode from "jwt-decode";
import { v4 as uuidv4 } from "uuid";

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const navigate = useNavigate();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  /* const [titleDrawer, setTitleDrawer] = React.useState(""); */
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const location = useLocation();

  const {
    state: { titleDrawer },
    setTitle,
  } = useContext(DomainContext);

  let decoded = jwt_decode(localStorage.getItem("token"));

  const { logout } = useContext(AuthContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClick1 = () => {
    setOpen1(!open1);
  };

  const handleDrawerTitleandRoute = (route, title) => {
    /* setTitleDrawer(title); */
    setTitle(title);
    navigate(route);
  };

  const drawer = (
    <div>
      <Toolbar
        children={
          <Stack direction={"row"} justifyContent={"center"}>
            {decoded.role === "admin" ? (
              <Chip
                color="success"
                icon={<SupervisorAccountTwoTone />}
                label="Admin"
              />
            ) : (
              <Chip
                color="info"
                icon={<AccountCircleTwoTone />}
                label="Operatore"
              />
            )}
          </Stack>
        }
      />
      <Divider />
      <List>
        {menuVoiceAdmin.map((text, index) => {
          if (decoded.role === "admin") {
            return (
              <ListItem key={index + uuidv4()} disablePadding>
                <ListItemButton
                  onClick={() =>
                    handleDrawerTitleandRoute(text.route, text.name)
                  }
                >
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText
                    primary={text.name}
                    style={{ textTransform: "capitalize" }}
                  />
                </ListItemButton>
              </ListItem>
            );
          } else if (!text.isAdmin) {
            return (
              <ListItem key={index + uuidv4()} disablePadding>
                <ListItemButton
                  onClick={() =>
                    handleDrawerTitleandRoute(text.route, text.name)
                  }
                >
                  <ListItemIcon>{text.icon}</ListItemIcon>
                  <ListItemText
                    primary={text.name}
                    style={{ textTransform: "capitalize" }}
                  />
                </ListItemButton>
              </ListItem>
            );
          }
        })}
        <ListItemButton onClick={handleClick1}>
          <ListItemIcon>
            <BarChartTwoTone />
          </ListItemIcon>
          <ListItemText
            primary="Stato"
            style={{ textTransform: "capitalize" }}
          />
          {open1 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/process/")}
            >
              <ListItemIcon>
                <AllInclusive />
              </ListItemIcon>
              <ListItemText
                primary={
                  decoded.role === "admin" ? "Tutti i Proccessi" : "Processi"
                }
                secondary={"in corso"}
              />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton /* onClick={handleClick} */>
          <ListItemIcon>
            <StorageTwoTone />
          </ListItemIcon>
          <ListItemText
            primary="Archivio"
            style={{ textTransform: "capitalize" }}
          />
          {/*  {true ? <ExpandLess /> : <ExpandMore />} */}
        </ListItemButton>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={() => navigate("/storage")}>
              <ListItemIcon>
                <CloudCircleTwoTone />
              </ListItemIcon>
              <ListItemText
                primary="Vista Key-words"
                secondary={"Archivio key-words per dominio"}
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/storage/top-keywords")}
            >
              <ListItemIcon>
                <KeyTwoTone />
              </ListItemIcon>
              <ListItemText
                primary="Top Key-words"
                secondary={"Top keywords utilizzate"}
              />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/storage/file")}
            >
              <ListItemIcon>
                <SaveAs />
              </ListItemIcon>
              <ListItemText
                primary="Importa File"
                secondary={"Riscrivi testo importato da file"}
              />
            </ListItemButton>
          </List>
        </Collapse>
      </List>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <AccountBoxTwoTone />
        </ListItemIcon>
        <ListItemText
          primary="Account"
          style={{ textTransform: "capitalize" }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} /* onClick={() => logout(navigate)} */>
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText primary="Impostazioni" secondary={"Utente"} />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => logout(navigate)}>
            <ListItemIcon>
              <LogoutTwoTone />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Collapse>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            style={{ textTransform: "capitalize" }}
          >
            {titleDrawer}
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        {props.child}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
